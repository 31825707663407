<template>
    <div>
        <el-table :data="message_lists" border style="width: 100%" v-loading="loading">
            <el-table-column prop="body" label="Message">
                <template #default="scope">
                    <TextMoreLessVue :text="scope.row.body"/>
                </template>
            </el-table-column>
            
            <el-table-column prop="published_at" width="300" label="Published at" align="center"></el-table-column>
        </el-table>
        <el-pagination
            :current-page="call_current_page"
            :page-size="perPage"
            layout="total, prev, pager, next"
            :total="total_call"
            @current-change="getCalls"
        />
    </div>
    
</template>
<script>
import axios from 'axios';
import TextMoreLessVue from '../../components/core/TextMoreLess.vue';
export default {
    name: 'PatientMessageLog',
    components: {
        TextMoreLessVue
    },
    data() {
        return {
            loading: false,
            message_lists: [],
            perPage: 5,
            total_call: 0,
            call_current_page: 1,
        }
    },
    created() {
        this.getMessageList();
    },
    methods: {
        getMessageList(value) {
            this.loading = true;
            if (Number(value)) {
                this.call_current_page = value;
                const offset = value - 1;
                var correctValue = offset * this.perPage;
            }
            
            axios.get(`/api/v2/agent/messages/patients/${this.$route.params.id}?limit=${this.perPage}&offset=${correctValue || 0}`).then(res => {
                this.message_lists = res.data.data;
                this.loading = false;
            })
        },
    },
}
</script>