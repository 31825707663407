<template>
    <el-dialog
        v-model="is_appointment_dialog"
        title="Update Appointment"
        class="appointment-status-modal"
        size="lg">
        <el-form
            label-position="top"
            label-width="300px"
            :model="formLabelAlign"
        >
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="new_patient_section d-flex">
                <div class="w-100">
                <el-form-item
                    label="Select Doctor"
                    prop="doctor_id"
                    :rules="[
                    {
                        required: true,
                        message: 'Please input doctor',
                        trigger: 'blur',
                    }
                    ]">
                <el-select class="w-100" v-model="appointment.doctor_id" filterable clearable
                            placeholder="Select Doctor" @change="doctorChange()">
                    <el-option
                        v-for="item in doctors"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id"
                    />
                </el-select>
                </el-form-item>
                </div>
            </div>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="appointment_details.is_paid != 1">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Appointment type">
              <el-radio-group v-model="appointment.appointment_type">
                <el-radio label="booked_appointment">Booked Appointment (Consultation)</el-radio>
                <el-radio label="clubfoot_appointment">Clubfoot Appointment</el-radio>
                <el-radio label="therapy_appointment">Therapy Appointment</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="d-flex justify-content-between align-items-center">
                <div class="w-100">
                    <el-date-picker
                        class="app_date_picker"
                        popper-class="my-datepicker"
                        v-model="appointment.schedule_date"
                        type="date"
                        placeholder="Pick a day"
                        :size="size"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        @change="clickDate"
                    />
                </div>
                </div>
            </el-col>
            </el-row>
            <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div v-if="chambers && appointment.schedule_date">
                <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="appointment.schedule_started_at"
                                    @slotNull="formDataNull"/>
                </div>
            </el-col>
            </el-row>
            <br/>
            <br/>
            <div class="text-center">
            <el-button type="dark" @click="is_appointment_dialog=false">
              Back
            </el-button>
            <el-button type="primary" @click="updateAppointment()">
              Confirm
            </el-button>
          </div>
        </el-form>
    </el-dialog>
</template>
<script>
import axios from 'axios';
import ChamberWithSlots from '../ChamberWithSlots';

export default {
  name: 'AppointmentUpdateModal',
  props: {
    is_appointment_details: {},
    appointment_details: {},
  },
  components: {
    ChamberWithSlots,
  },
  data() {
    return {
      is_appointment_dialog: false,
      loading: false,
      doctors: [],
      appointment: {
        doctor_id: '',
        appointment_type:'',
        workplace_id: '',
        schedule_end_at: '23:59',
        schedule_started_at: '',
        schedule_date: null,
      },
      chambers: [],
      doctorInformation: {},
    };
  },
  created() {
    this.is_appointment_dialog = true;
    this.getDoctors();
    if (this.appointment_details) {
      console.log(this.appointment_details);
      this.appointment.doctor_id = this.appointment_details.doctor_id;
      this.appointment.appointment_type = this.appointment_details.appointment_type;
      this.appointment.schedule_date = this.appointment_details.schedule_date;
      this.appointment.schedule_started_at = this.appointment_details.schedule_started_at;
      this.appointment.workplace_id = this.appointment_details.workplace_id;
      this.clickDate();
    }
  },
  methods: {
    getDoctors() {
      this.loading = true;
      axios.get(`/api/v2/doctor/center-wise/list?appointment=yes${this.isToday ? '&has_schedule_today=yes' : ''}`)
        .then((response) => {
          this.doctors = response.data.data;
          this.loading = false;
        });
    },
    workPlaceId(id) {
      this.appointment.workplace_id = id.id;
      this.appointment.working_schedule_time_slot = id.working_schedules[0].time_per_slot;
    },
    clickDate() {
      // this.appointment.schedule_date = null;
      // this.appointment.schedule_started_at = null;
      this.chambers = [];
      this.loading = true;

      const url = `/api/v1/doctor/chamber/list?schedule_date=${this.appointment.schedule_date}&doctor_id=${this.appointment.doctor_id}`;
      axios
        .get(url)
        .then((res) => {
          this.chambers = res.data.data;
          this.loading = false;
        });
    },
    disabledDate(time) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date;
    },
    doctorChange() {
      this.appointment.schedule_date = null;
      this.appointment.schedule_started_at = null;
    },
    updateAppointment() {
      axios.put(`/api/v2/appointment/${this.appointment_details.id}/doctor-schedule-update`, this.appointment).then((res) => {
        if (res.data.status_code == 200) {
          this.is_appointment_dialog = false;
          this.$emit('updatedAppontment', res.data.data);
          this.$notify({
            title: 'Success',
            message: 'Successfully Appointment updated',
            type: 'success',
            duration: 2000,
          });
        } else {
          this.$notify({
            title: 'Failed',
            message: 'Appointment Failed',
            type: 'danger',
            duration: 2000,
          });
        }
      });
    },
  },
  watch: {
    is_appointment_dialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<style>
.w-100 {
    width: 100% !important;
}
</style>
