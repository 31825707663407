<template>
<div>
  <el-dialog v-model="appointmentModal" title="Set Appointment" class="appointment-status-modal" :show-close="false">

  <el-row justify="center">
    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-100">
          <el-date-picker
              class="app_date_picker"
              popper-class="my-datepicker"
              v-model="form.date_value"
              type="date"
              placeholder="Pick a day"
              :shortcuts="shortcuts"
              :size="size"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="clickDate"
          />
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row justify="center">
    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
      <div v-if="chambers && form.date_value">
        <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="form.slot_value"/>
      </div>
    </el-col>
  </el-row>
  </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
import ChamberWithSlots from '../ChamberWithSlots';

export default {
  name: 'AppointmentModal',
  props: {
    reschedule: {},
  },
  components: {
    ChamberWithSlots,
  },
  data() {
    return {
      form: {
        date_value: '',
        service_type: 'visit',
        patient_id: '',
        slot_value: '',
        workplace_id: '',
        doctor_id: '',
      },
      doctor_type: 'doctor',
      doctor_types: [
        {
          text: 'Doctor',
          value: 'doctor',
        },
        {
          text: 'Physio',
          value: 'physio',
        },
      ],
      doctors: [],
      shortcuts: [
        {
          text: 'Today',
          value: new Date(),
        },
        {
          text: 'Yesterday',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
        {
          text: 'A week ago',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          },
        },
      ],
      size: 'large',
      chambers: [],
      doctor: {},
      appointmentModal: '',
      loading: false,
    };
  },
  created() {
    this.appointmentModal = true;
    this.getDcotors();
    if (this.reschedule) {
      // console.log('res', this.reschedule)
      this.form.date_value = this.reschedule.schedule_date;
      this.form.doctor_id = this.reschedule.doctor_id;
      this.form.patient_id = this.reschedule.patient_id;
    }
  },
  computed: {
    filterDoctor() {
      return this.doctors.filter((doctor) => doctor.sub_type == this.doctor_type);
    },
  },
  methods: {
    clickVirtual() {
      this.form.date_value = '';
    },
    clickVisit() {
      this.form.date_value = '';
    },
    getDcotors() {
      this.loading = true;
      axios.post('/api/v1/doctor/connection/user/list?platform=backoffice')
        .then((response) => {
          this.doctors = response.data.data;
          this.loading = false;
        });
    },
    profile() {
      this.chambers = {};
      this.form.date_value = '';
      this.form.patient_id = '';
      this.loading = true;
      axios.get(`/api/v1/user/profile/details/${this.form.doctor_id}?platform=backoffice`).then((res) => {
        this.doctor = res.data.data;
        this.loading = false;
      });
    },
    clickDate() {
      this.loading = true;
      axios
        .get(`/api/v1/doctor/chamber/list?schedule_date=${this.form.date_value}&doctor_id=${this.form.doctor_id}${this.form.service_type == 'visit' ? '' : '&type=telemedicine'}`)
        .then((res) => {
          this.chambers = res.data.data;
          this.loading = false;
        });
    },
    workPlaceId(id) {
      this.form.workplace_id = id;
    },
    doctorDetails(doctor) {
      axios.get(`api/v1/user/profile/details/${doctor.category_id}`).then((response) => {
        this.doctorInformation = response.data.data;
      });
      this.doctor_details_modal = true;
    },
  },
  watch: {
    appointmentModal(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('modalClosed');
      }
    },
  },
};
</script>

<style>
.app_date_picker {
  width: 100% !important;
}
</style>
