<template>
    <el-dialog
        v-model="message_dailog"
        title="Send Message"
        class="appointment-status-modal"
        size="lg">
            <MessageForm @dialogClose="message_dailog=false" :patient_id="patient_id"  :log_id="log_id" @callApi="callApi"/>
        <div class="mt-30">
          <h3>Recent Message logs:</h3>
          <el-table :data="message_lists" border style="width: 100%" v-loading="loading">
            <el-table-column prop="body"  align="center" label="Message">
              <template #default="scope">
                <TextMoreLess :text="scope.row.body"/>
              </template>
            </el-table-column>
            
            <el-table-column prop="published_at" width="200" label="Published at" align="center"></el-table-column>
          </el-table>
        </div>

    </el-dialog>
</template>
<script>
import MessageForm from '../form/MessageForm.vue';
import axios from 'axios';
import TextMoreLess from '../core/TextMoreLess.vue';
export default {
    name: 'MessageModal',
    components: {
        MessageForm,
        TextMoreLess,
    },
    props: {
        patient_id: {
        type: Number,
        required: false,
        },
        log_id: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            message_dailog: false,
            message_lists: [],
            loading: false,
        }
    },
  created() {
    this.message_dailog = true;
    this.getMessageList();
  },
  methods: {
    getMessageList() {
      this.loading = true;
      axios.get(`/api/v2/agent/messages/patients/${this.patient_id}?limit=3`).then( (res) => {
        this.message_lists = res.data.data;
        this.loading = false;
      });
    },
  },
  watch: {
    message_dailog(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('modalClosed');
      }
    },
  },
};
</script>
