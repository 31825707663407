<template>
  <el-card shadow="never" style="border: 0px">
    <div class="profile-details" v-loading.fullscreen.lock="loading">
      <el-row>
        <el-col :sm="12" :md="12" :lg="12">
          <UserCard :name="patient.fullname ? patient.fullname : patient.first_name" :image="patient.image"
                    :phone_number="patient.phone_number">
            {{ patient.age }}
          </UserCard>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12">
          <div>
            <p>Registration no: {{ patient.registration_number ? patient.registration_number : '-' }}</p>
            <p>Gender: {{ patient.gender ? patient.gender : '-' }}</p>
            <p>Location: {{ patient.location ? patient.location : '-' }}</p>
            <el-tag class="ml-2" style="font-size: 16px; font-weight: 600" type="danger" v-if="patient.cf_patient_details && patient.cf_patient_details.is_relapsed">
              Relapsed - {{ patient.cf_patient_details.relapse_started_at }}
            </el-tag>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
  <br>
  <el-card shadow="never" style="border: 0px">
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Uploaded Prescription" name="prescription" v-if="checkUsrPermissions('document add')">
          <el-button type="primary" @click="uploadDocuments('prescription')">Upload Prescription</el-button>
          <div v-if="prescriptions.length" class="demo-image">
            <div v-for="prescription in prescriptions" :key="prescription.id" class="block">
              <el-image style="width: 300px; height:300px" :src="assetUrl(prescription.url)" :fit="fit"
                        @click="() => showImg(prescription,prescription.id)" />
              <br>
              <a target="_blank" :href="assetUrl(prescription.url)" download>Download</a>
            </div>
            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>

          <el-empty v-else :image-size="200"/>

        </el-tab-pane>
        <el-tab-pane label="Orko Prescription" name="orko_prescription"
                     v-if="checkUsrPermissions('document add')">
          <OrkoPrescription v-if="is_orko_prescription"/>
        </el-tab-pane>
        <el-tab-pane label="Reports" name="report" v-if="checkUsrPermissions('document add')">
          <el-button type="primary" @click="uploadDocuments('report')">Upload Reports</el-button>
          <div v-if="reports.length" class="demo-image">

            <div v-for="(report, index) in reports" :key="index" class="block" @click="() => showImg(report,index)">
              <el-image style="width: 300px; height:300px" :src="assetUrl(report.url)" :fit="fit"/>
              <br>
              <a target="_blank" :href="assetUrl(report.url)" download>Download</a>
            </div>

            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>
          <el-empty v-else :image-size="200"/>
        </el-tab-pane>
        <el-tab-pane label="Image" name="image" v-if="checkUsrPermissions('document add')">
          <el-button type="primary" @click="uploadDocuments('image')">Upload Image</el-button>
          <div v-if="images.length" class="demo-image">

            <div v-for="(image, index) in images" :key="index" class="block" @click="() => showImg(image,index)">
              <el-image style="width: 300px; height:300px" :src="assetUrl(image.url)" :fit="fit"/>
              <br>
              <a target="_blank" :href="assetUrl(image.url)" download>Download</a>
            </div>

            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>
          <el-empty v-else :image-size="200"/>
        </el-tab-pane>
        <el-tab-pane label="Medical record" name="medical_record"
                     v-if="checkUsrPermissions('prescription list by patient')">
          <PatientMedicalRecord v-if="is_medical_record"/>
        </el-tab-pane>
        <el-tab-pane label="Update profile" name="update_profile" v-if="checkUsrPermissions('patient profile update')">
          <PatientForm v-if="patient.id" :patient="patient" @selectedPatient="getPatientDetails"/>
        </el-tab-pane>
        <el-tab-pane label="Club Foot Details" name="club_foot_details" v-if="checkUsrPermissions('User profile')">
          <ZeroClubFootDetails/>
        </el-tab-pane>
        <el-tab-pane label="Appointments" name="appointments"
                     v-if="checkUsrPermissions('Get All Appointment List by Patient')">

          <PatientAppointment v-if="is_appointment"/>
        </el-tab-pane>
        <el-tab-pane label="Pirani Score" name="pirani_score"
                     v-if="checkUsrPermissions('Get pirani scores by patient')" @visitSlotUpdated="visitSlotUpdated">

          <PatientPirani v-if="is_pirani"/>
        </el-tab-pane>
        <el-tab-pane label="Invoices" name="invoices" v-if="checkUsrPermissions('Invoice list')">
          <PatientInvoice v-if="is_invoice"/>
        </el-tab-pane>
        <el-tab-pane label="Call Logs" name="call_log" v-if="checkUsrPermissions('Agent Call Log list by patient')">
          <PatientCallLog v-if="isCallLog"/>
        </el-tab-pane>
        <el-tab-pane label="Message Logs" name="message_log" v-if="checkUsrPermissions('Get Messages by patient')">
          <PatientMessageLog v-if="isMessage"/>
        </el-tab-pane>
      </el-tabs>
      <PatientDocumentUploadModal :document_type="document_type" v-if="document_upload_dialog"
                                  @closeModal="closeModal"/>
    </div>
  </el-card>
</template>

<script>
import axios from 'axios';
import VueEasyLightbox from 'vue-easy-lightbox';
import { CommonFunction } from '@/mixins/CommonFunction'; // work
// eslint-disable-next-line import/extensions
import PatientForm from '@/components/form/PatientForm';
import ZeroClubFootDetails from '@/views/patient/ZeroClubFootDetails';
import PatientInvoice from '@/views/patient/PatientInvoices';
import PatientAppointment from '@/views/patient/PatientAppointments';
import PatientCallLog from '@/views/patient/PatientCallLog';
import UserCard from '@/components/core/UserCard';
import PatientPirani from '@/views/patient/PatientPirani';
import PatientMedicalRecord from '@/views/patient/PatientMedicalRecord';
import PatientDocumentUploadModal from '../../components/modal/PatientDocumentUploadModal'; // work
import PatientMessageLog from '@/views/patient/PatientMessageLog';
import OrkoPrescription from '@/views/patient/OrkoPrescription'
export default {
  name: 'PatientDetails',
  title: 'Patient Details',
  components: {
    VueEasyLightbox,
    PatientDocumentUploadModal,
    PatientForm,
    ZeroClubFootDetails,
    PatientInvoice,
    PatientAppointment,
    PatientCallLog,
    UserCard,
    PatientPirani,
    PatientMedicalRecord,
    PatientMessageLog,
    OrkoPrescription,
  },
  mixins: [CommonFunction],
  data() {
    return {
      loading: false,
      patient: {},
      activeName: this.$route.query.zeroclubfoot ? 'club_foot_details' : 'prescription',
      prescriptions: [],
      reports: [],
      images: [],
      fit: 'cover',
      imgsRef: [],
      indexRef: 0,
      visibleRef: false,
      document_upload_dialog: false,
      document_type: '',
      is_invoice: false,
      is_appointment: false,
      is_pirani: false,
      is_medical_record: false,
      isCallLog: false,
      isMessage: false,
      is_orko_prescription: false,
    };
  },
  created() {
    this.getPatientDetails();
    this.getReports();
  },
  methods: {
    getPatientDetails() {
      this.loading = true;
      axios.get(`/api/v1/user/profile/details/${this.$route.params.id}`)
        .then((response) => {
          this.patient = response.data.data;
          this.loading = false;
        });
    },
    getReports() {
      this.loading = true;
      axios.get(`/api/v1/patient/document/list?patient_id=${this.$route.params.id}`)
        .then((response) => {
          response.data.data.map((item) => {
            if (item.type == 'prescription') {
              this.prescriptions.push(item);
            } else if (item.type == 'image') {
              this.images.push(item);
            } else {
              this.reports.push(item);
            }
          });
          this.loading = false;
        });
    },
    assetUrl(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    showImg(imgObject, index) {
      this.imgsRef = this.assetUrl(imgObject.url);
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    uploadDocuments(value) {
      this.document_upload_dialog = true;
      this.document_type = value;
    },
    closeModal(value) {
      this.document_upload_dialog = false;
      if (value) {
        value.forEach((item) => {
          if (item.type == 'prescription') {
            this.prescriptions.push(item);
          } else if (item.type == 'image') {
            this.images.push(item);
          } else {
            this.reports.push(item);
          }
        });
      }

      // this.getReports();
    },
    handleClick(event) {
      if (event.props.name == 'update_profile') {
        this.getPatientDetails();
      } else if (event.props.name == 'invoices') {
        this.is_invoice = true;
      } else if (event.props.name == 'pirani_score') {
        this.is_pirani = true;
      } else if (event.props.name == 'appointments') {
        this.is_appointment = true;
      } else if (event.props.name == 'medical_record') {
        this.is_medical_record = true;
      } else if (event.props.name == 'call_log') {
        this.isCallLog = true;
      } else if (event.props.name == 'message_log') {
        this.isMessage = true;
      } else if (event.props.name === 'orko_prescription') {
        this.is_orko_prescription = true;
      }
    },
    visitSlotUpdated() {
      this.is_pirani = true;
    },

  },
};
</script>

<style scoped>
h1, h2, h3, h4, h5, p {
  margin: 5px 0px;
  padding: 0;
}

.demo-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  /*width: 20%;*/
  box-sizing: border-box;
  vertical-align: top;
  margin: 10px 10px;
  cursor: pointer;
}

.demo-image .block:last-child {
  border-right: none;
}

.demo-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin: 10px 10px;
}
</style>
