<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row justify="space-between" :gutter="20" align="middle">
      <el-col :sm="12" :md="6" :lg="6">
        <h3>Today's and Upcoming Appointments</h3>
      </el-col>
      <el-col :sm="12" :md="6" :lg="6" v-if="authUser.user_type == 'executive' || authUser.user_type == 'crm-manager'">
        <el-select @change="getAppointments()" class="m-2" v-model="center_id" placeholder="Select Center" size="large">
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :sm="20" :md="12" :lg="12">
        <el-form
            :model="search_form"
            :rules="search_rules"
            label-position="top"
            @submit.prevent="searchPatient('search_form')"
            ref="search_form">
          <div class="d-flex align-items-center">
            <el-form-item label="Search Patient" prop="patient_q" class="w-100">
              <el-input
                  v-model="search_form.patient_q"
                  placeholder="Search Patient Phone Number"
                  class="input-with-select"
                  type="number"
              >
              </el-input>
            </el-form-item>

            <el-button type="success" class="mt-5 mx-5" @click="searchPatient('search_form')" round>Search</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Select user type</p>
        <el-select v-model="search_type" class="m-2 w-100" size="large" clearable>
          <el-option
              v-for="item in search_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Name/Phone Number</p>
        <el-input v-model="search" size="large"/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Select Status</p>
        <el-select v-model="search_status" class="m-2 w-100" size="large" clearable>
          <el-option
              v-for="item in appointment_status_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40 d-flex justify-content-center">
        <el-button size="large" @click="getAppointments" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>

      </el-col>
      <el-col :xs="4" :sm="4" :md="2" :lg="2" :xl="2" class="mt-40">
        <el-button type="warning" size="large" @click="getAppointments" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right"/>
        </el-button>
      </el-col>
    </el-row>
    <br/>
    <!--  Mobile components  -->
    <div class="mobile">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" v-for="(item,index) in tableData" :key="index">
          <div class="bg-white custom-box-card box-card mt-5">
            <div class="card-header">
              <div class="d-flex flex-direction-row justify-content-between align-items-center">
            <span>
              {{ item.appointment_date }}

            </span>
                <div class="">
                  <el-dropdown>
                <span class="el-dropdown-link">
                  <el-button type="info" circle>
                    <el-icon><More/></el-icon>
                  </el-button>
                </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <div
                            v-if="item.appointment_status != 'canceled' && checkUsrPermissions('appointment status change for agent') && authUser.sub_type != 'physio_assistant'">

                          <el-dropdown-item v-if="item.appointment_status != 'canceled'"
                                            @click="appointmentStatusChanged(item.id, 'cancel')">Appointment Cancel
                          </el-dropdown-item>

                          <el-dropdown-item
                              v-if="item.appointment_status != 'rescheduled' && checkUsrPermissions('Book appointment') && authUser.sub_type != 'physio_assistant'"
                              @click="appointmentRescheduled(item)">Appointment Rescheduled
                          </el-dropdown-item>
                        </div>
                        <el-dropdown-item @click="generateInvoice(item)"
                                          v-if="checkUsrPermissions('Invoice store') && item.is_paid != 1">Invoice
                        </el-dropdown-item>
                        <el-dropdown-item @click="examinationsModalOpen(scope.row)"
                                          v-if="checkUsrPermissions('Add medical examinations to appointment')"
                        >Add Examinations
                        </el-dropdown-item>
                        <el-dropdown-item @click="examinationsViewModalOpen(item)"
                                          v-if="checkUsrPermissions('Get medical examinations by appointment')">
                          View Examinations
                        </el-dropdown-item>
                        <el-dropdown-item @click="examinationsModalOpen(scope.row)"
                                          v-if="checkUsrPermissions('Add medical examinations to appointment')"
                        >Add Pirani Score
                        </el-dropdown-item>
                        <el-dropdown-item @click="examinationsViewModalOpen(item)"
                                          v-if="checkUsrPermissions('Get medical examinations by appointment')">
                          View Pirani Score
                        </el-dropdown-item>

                        <el-dropdown-item @click="adviceModalOpen(item)"
                                          v-if="checkUsrPermissions('Add medical advices to appointment') && item.is_paid == 1"
                        >Add Advice
                        </el-dropdown-item>
                        <el-dropdown-item @click="adviceDetailsModalOpen(item)"
                                          v-if="checkUsrPermissions('Get medical advices to appointment') && item.is_paid == 1">
                          View Advice
                        </el-dropdown-item>

                        <!--                        <el-dropdown-item @click="sessionStartEnd(item)"-->
                        <!--                                          v-if="checkUsrPermissions('Appointment session update') && item.appointment_type == 'therapy_appointment' && !item.session_completed_at && item.is_paid == 1">-->
                        <!--                          Session Start/Stop-->
                        <!--                        </el-dropdown-item>-->
                        <!--                        -->
                        <!--                        <el-dropdown-item @click="generateInvoice(item)">Invoice</el-dropdown-item>-->
                        <!--                        <el-dropdown-item @click="examinationsModalOpen(item)">Examinations</el-dropdown-item>-->
                        <!--                        <el-dropdown-item @click="sessionStartEnd(item)"-->
                        <!--                                          v-if="item.appointment_type == 'therapy_appointment' && !item.session_completed_at">-->
                        <!--                          Session Start/Stop-->
                        <!--                        </el-dropdown-item>-->

                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <hr>
            <div class="card-body">
              <div class="d-flex  justify-content-between">
                <div>
                  <UserCard
                      :prefix="item.doctor_prefix"
                      :name="item.doctor_name"
                      :phone_number="item.doctor_phone_number"
                      :image="item.doctor_image"></UserCard>
                </div>
                <br>
                <div>
                  <UserCard :name="item.patient_name"
                            :phone_number="item.patient_phone"
                            :image="item.patient_image"></UserCard>

                </div>
                <br>

              </div>
              <div>
                <el-tag class="ml-2 w-100">
                  {{ item.appointment_type }}
                </el-tag>
              </div>
            </div>
            <div class="card-footer bg-light-black">
              <div class="">
                <div class="d-flex flex-direction-row justify-content-between">
                  <div>
                    <el-tag class="ml-2" type="success">{{ item.service_type }}</el-tag>
                  </div>
                  <div class="mx-5">
                    <el-tag class="ml-2 " type="success" v-if="item.appointment_status == 'booked'">
                      {{ item.appointment_status }}
                    </el-tag>
                    <el-tag class="ml-2 " type="danger" v-else>{{ item.appointment_status }}</el-tag>
                  </div>
                  <div>
                    <el-tag class="ml-2" v-if="item.is_paid == 1" type="success">Paid</el-tag>
                    <el-tag class="ml-2" v-else-if="item.is_paid == 2" type="info">Partial paid (AP)</el-tag>
                    <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- Desktop component -->

    <div class="desktop">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="doctor_name" label="Doctor" width="200">
          <template #default="scope">
            <UserCard :name="scope.row.doctor_name"
                      :phone_number="scope.row.doctor_phone_number"
                      :image="scope.row.doctor_image"></UserCard>
            <el-tag class="ml-2 w-100" v-if="scope.row.institute_type == 'Therapy'">
              {{ scope.row.institute_type }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="patient_name" label="Patient" width="200">
          <template #default="scope">
            <UserCard :name="scope.row.patient_name"
                      :phone_number="scope.row.patient_phone"
                      :image="scope.row.patient_image"></UserCard>
          </template>
        </el-table-column>
        <el-table-column prop="appointment_date" label="Booking time" width="180">
          <template #default="scope">
            <p class="mt-0">{{ scope.row.schedule_date }}
              <el-tag class="ml-2" type="success" v-if="scope.row.is_followup == 1">Followup</el-tag>
            </p>
            <el-tag class="ml-2" type="success">{{ scope.row.schedule_started_at }}</el-tag>

          </template>
        </el-table-column>
        <el-table-column prop="appointment_details" label="Details" width="180">
          <template #default="scope">
            <p class="mb-0">
              Service Type: <b>{{ scope.row.service_type }}</b> <br/>
              Booked By: <b>{{ scope.row.booked_by?scope.row.booked_by.fullname:'N/A' }}</b>
            </p>
          
            <p class="mt-0">Appointment status:
              <el-tag class="ml-2 " type="success" v-if="scope.row.appointment_status == 'booked'">
                {{ scope.row.appointment_status }}
              </el-tag>
              <el-tag class="ml-2 " type="danger" v-else>{{ scope.row.appointment_status }}</el-tag>

            </p>
          </template>
        </el-table-column>
        <el-table-column prop="is_paid" label="Payment Status" width="140">
          <template #default="scope">
            <div>
              <el-tag class="ml-2" v-if="scope.row.is_paid == 1" type="success">Paid</el-tag>
              <!--            <el-tag class="ml-2" v-else-if="scope.row.is_paid == 2" type="info">Partial paid (AP)</el-tag>-->
              <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="appointment_type" label="Type" width="120">

          <template #default="scope">
            <el-tag
                type="success"
                class="mx-1"
                effect="dark" v-if="scope.row.appointment_type == 'booked_appointment'">
              Consultancy
            </el-tag>
            <el-tag
                type="success"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'quick_care'">
              Consultancy
            </el-tag>
            <el-tag
                type="success"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'walkin_appointment'">
              Consultancy
            </el-tag>
            <el-tag
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'therapy_appointment'">
              Therapy Appointment
            </el-tag>
            <el-tag
                type="warning"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'clubfoot_appointment'">
              Clubfoot Appointment
            </el-tag>
            <el-tag
                type="warning"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'dental_appointment'">
              Dental Appointment
            </el-tag>
            <el-tag
                v-else
                type="info"
                class="mx-1"
                effect="dark">
              Therapy Session
            </el-tag>

          </template>
        </el-table-column>
        <el-table-column prop="invoice_uid" label="Invoice No" width="150">
          <template #default="scope">
            <router-link v-if="scope.row.invoice_uid" :to="`/invoice/${scope.row.invoice_uid}`" target="_blank">{{ scope.row.invoice_uid?scope.row.invoice_uid.replace('INV-',''):'N/A' }}</router-link>
            <span v-else>N/A</span>
          </template>
        </el-table-column>
        <el-table-column label="Therapy Session" width="146">
          <template #default="scope">
            <div v-if="scope.row.session_started_at && scope.row.session_completed_at" style="text-align: center">
              <el-tag class="ml-2" effect="dark" type="success">
                {{ calculateSessionMinutes(scope.row.session_started_at, scope.row.session_completed_at) }} mins
              </el-tag>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="actions" label="Actions" width="110">
          <template #default="scope">
            <el-dropdown>
            <span class="el-dropdown-link">
          <el-button type="info" circle>
              <el-icon><More/></el-icon>
          </el-button>
          </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <div v-if="scope.row.appointment_status != 'served' && scope.row.appointment_status != 'canceled'">

                    <el-dropdown-item
                        v-if="scope.row.appointment_status == 'booked' || scope.row.appointment_status == 'expired'"
                        @click="appointmentStatusUpdate(scope.row, 'update')">Appointment Update
                    </el-dropdown-item>

                    <el-dropdown-item
                        v-if="scope.row.appointment_status != 'canceled' && checkUsrPermissions('appointment status change for agent') && authUser.sub_type != 'physio_assistant'"
                        @click="appointmentStatusChanged(scope.row.id, 'cancel')">Appointment Cancel
                    </el-dropdown-item>

                    <el-dropdown-item
                        v-if="scope.row.appointment_status != 'rescheduled' && checkUsrPermissions('Book appointment') && authUser.sub_type != 'physio_assistant'"
                        @click="appointmentRescheduled(scope.row)">Appointment Rescheduled
                    </el-dropdown-item>

                  </div>

                  <el-dropdown-item @click="generateInvoice(scope.row)"
                                    v-if="checkUsrPermissions('Invoice store') && scope.row.is_paid != 1 && scope.row.appointment_status!='canceled'">
                    Invoice
                  </el-dropdown-item>
                  <el-dropdown-item @click="examinationsModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Add medical examinations to appointment')"
                  >Add Examinations
                  </el-dropdown-item>

                  <el-dropdown-item @click="examinationsViewModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Get medical examinations by appointment')">
                    View Examinations
                  </el-dropdown-item>
                  <!-- <el-dropdown-item @click="piraniScoreModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Add medical examinations to appointment')"
                  >Add Pirani Score
                  </el-dropdown-item>
                  <el-dropdown-item @click="piraniScoreViewModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Get medical examinations by appointment')">
                    View Pirani Score
                  </el-dropdown-item> -->
                  <el-dropdown-item @click="adviceModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Add medical advices to appointment') && scope.row.is_paid == 1"
                  >Add Advice
                  </el-dropdown-item>
                  <el-dropdown-item @click="adviceDetailsModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Get medical advices to appointment') && scope.row.is_paid == 1">
                    View Advice
                  </el-dropdown-item>

                  <el-dropdown-item @click="commentModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Add comments to appointment') && scope.row.is_paid == 1"
                  >Add Comment
                  </el-dropdown-item>

                  <el-dropdown-item @click="commentDetailsModalOpen(scope.row)"
                                    v-if="checkUsrPermissions('Get comments to appointment') && scope.row.is_paid == 1">
                    View Comment
                  </el-dropdown-item>

                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-model:current-page="currentPage"
          :page-size="per_page"
          layout="total, prev, pager, next"
          :total="total_count"
          @current-change="getAppointments"
      />

    </div>
    <!--  Appointment status change  -->
    <el-dialog
        v-model="dialogVisible"
        :title="`${action ? action.toUpperCase() : ''} Appointment`"
        class="appointment-status-modal">
      <el-form :model="form" label-position="top"
               label-width="100px" :rules="cancelRules" ref="form">
        <el-form-item label="Comment" prop="comment">
          <el-input v-model="form.comment" type="textarea"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button @click="chaneStatus('form')">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>

    <!--  Examination modal  -->

    <!--  Rescheduled Dialog -->
    <el-dialog
        v-model="rescheduleDialogVisible"
        title="Reschedule Appointment"
        class="appointment-status-modal"
    >

      <el-form :model="form" label-position="top"
               label-width="100px">
        <el-form-item label="Comment *">
          <el-input v-model="form.comment" type="textarea"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button @click="chaneStatus">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>

    <!--  Invoice modal  -->
    <InvoiceGenerate v-if="dialogInvoice" :invoice_details="invoice_details" @closeModal="dialogInvoice=false"/>

    <SessionStartStopModal v-if="sessionStartEndDialog" :invoice_details="invoice_details"
                           @closeModal="sessionStartEndDialog=false" @listUpdate="responseUpdated"/>

    <!--  Search patients  -->
    <el-dialog v-model="searchDoctorDialog" class="appointment-status-modal">
      <div v-if="patients.length">
        <div v-for="(patient, index) in patients" :key="index" class="single_patient d-flex justify-content-between">
          <div>
            <h3 class="mt-0 mb-0">{{ patient.fullname }}</h3>
            <p class="mb-0 mt-0">{{ patient.phone_number }}</p>
          </div>
          <div>
            <router-link :to="`/appointments?id=${patient.id}&name=${patient.fullname}`">
              <el-button type="success">Get Appointment</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <el-empty :image-size="100" description="No patient found"/>
        <!--        <el-button type="success" @click="createNewPatient">Create New Patient</el-button>-->
        <PatientForm :phone_number="search_form.patient_q" @dialogClose="dialogClose"
                     @selectedPatient="selectedPatient"/>
      </div>

    </el-dialog>
    <!-- Payment dialog -->
    <el-dialog v-model="paymentDialogVisible" class="appointment-status-modal">
      <el-form :model="payment" :label-position="labelPosition"

               :rules="rules"
               ref="payment">
        <el-form-item label="Amount" prop="amount">
          <el-input v-model="payment.amount" disabled/>
        </el-form-item>
        <el-form-item label="Transaction type" prop="region">
          <el-select v-model="payment.transaction_type">
            <el-option label="Cash" value="cash"/>
            <!--            <el-option label="Bank" value="bank"/>-->
            <!--            <el-option label="Mobile Banking" value="mobile_bank"/>-->
          </el-select>
        </el-form-item>
        <el-form-item label="Payment options" prop="resource">
          <el-radio-group v-model="payment.payment_option">
            <el-radio label="1" value="1">Paid</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="paymentDialogVisible = false">Cancel</el-button>
        <el-button @click="makePayment('payment')">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>

    <AppointmentModal @modalClosed="paymentModalClose" v-if="is_appointment_modal" :reschedule="reschedule_data"/>

    <ExaminationModal @closeModal="examinationDialog=false" v-if="examinationDialog"
                      :examinations_signs="examinations_signs" :appointment_details="appointment_details"/>

    <PiraniScoreModal @closeModal="piraniScoreDialog=false" v-if="piraniScoreDialog"
                      :examinations_signs="examinations_signs" :appointment_details="appointment_details"/>

    <ExaminationDetailsModal @closeModal="examinations_details_dialog=false"
                             v-if="examinations_details_dialog"
                             :examinations_details="examinations_details"/>
    <PiranScoreViewModal @closeModal="pirani_score_view_dialog=false"
                         v-if="pirani_score_view_dialog"
                         :examinations_details="examinations_details"/>

    <AddAdvice v-if="advice_dialog" @closeModal="advice_dialog=false" :appointment_details="appointment_details"/>
    
    <AddComment v-if="comment_dialog" @closeModal="comment_dialog=false" :appointment_details="appointment_details"/>

    <AdviceDetails v-if="advice_details_dialog" @closeModal="advice_details_dialog=false"
                   :appointment_details="appointment_details"/>

    <CommentDetails v-if="comment_details_dialog" @closeModal="comment_details_dialog=false"
                   :appointment_details="appointment_details"/>

    <ApopointmentUpdateModal v-if="appointment_update_dailog" :appointment_details="appointment_details" @closeModal="appointment_update_dailog=false" @updatedAppontment="updatedAppontment"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import InvoiceGenerate from '@/components/modal/InvoiceGenerate';
import SessionStartStopModal from '@/components/modal/SessionStartStopModal';
import ExaminationDetailsModal from '@/components/modal/ExaminationDetailsModal';
import ExaminationModal from '@/components/modal/ExaminationModal';
import AddAdvice from '@/components/modal/AddAdvice';
import AddComment from '@/components/modal/AddComment';
import AdviceDetails from '@/components/modal/AdviceDetails';
import CommentDetails from '@/components/modal/CommentDetails';
import PiraniScoreModal from '@/components/modal/PiraniScoreModal';
import PiranScoreViewModal from '@/components/modal/PiranScoreViewModal';
import { CenterMixins } from '@/mixins/CenterMixins';
import ApopointmentUpdateModal from '@/components/modal/AppointmentUpdateModal';
import PatientForm from '../../components/form/PatientForm';
import AppointmentModal from '../../components/modal/AppointmentModal';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'MyAppointments',
  components: {
    UserCard,
    AppointmentModal,
    PatientForm,
    InvoiceGenerate,
    SessionStartStopModal,
    ExaminationDetailsModal,
    ExaminationModal,
    AddAdvice,
    AddComment,
    AdviceDetails,
    CommentDetails,
    PiraniScoreModal,
    PiranScoreViewModal,
    ApopointmentUpdateModal,
  },
  mixins: [CenterMixins],
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  data() {
    return {
      tableData: [],
      search: '',
      loading: false,
      dialogVisible: false,
      paymentDialogVisible: false,
      form: {
        comment: '',
      },
      action: '',
      appointment_id: '',
      dialogInvoice: false,
      invoice_details: {},
      schedule_date: null,
      start_date: null,
      end_date: null,
      payment_details: {},
      payment: {
        amount: '',
        transaction_type: 'cash',
        payment_option: '1',
      },
      labelPosition: 'top',
      search_type: 'patient',
      search_status: '',
      search_type_options: [
        {
          label: 'Doctor',
          value: 'doctor',
        },
        {
          label: 'Patient',
          value: 'patient',
        },
      ],
      appointment_status_options: [
        {
          label: 'booked',
          value: 'booked',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'rescheduled',
          value: 'rescheduled',
        },
        {
          label: 'canceled',
          value: 'canceled',
        },
        {
          label: 'served',
          value: 'served',
        },
        {
          label: 'expired',
          value: 'expired',
        },
        {
          label: 'delayed',
          value: 'delayed',
        },
        {
          label: 'patient-absent',
          value: 'patient-absent',
        },
        {
          label: 'doctor-absent',
          value: 'doctor-absent',
        },
      ],
      reschedule_data: {},
      rescheduleDialogVisible: false,
      is_appointment_modal: false,
      rules: {
        amount: [
          {
            required: true,
            message: 'Please input  amount field',
          },
        ],
      },
      cancelRules: {
        comment: [
          {
            required: true,
            message: 'Please input  amount field',
          },
        ],
      },
      patient_q: '',
      search_form: {
        patient_q: '',
      },
      search_rules: {
        patient_q: [
          {
            required: true,
            max: 11,
            message: 'Please input number',
          },
        ],
      },
      searchDoctorDialog: false,
      patients: [],
      is_change_modal_open: false,
      sessionStartEndDialog: false,
      examination: {
        vital_signs: '',
        vital_sign_value: '',
        general_examination: '',
        systemic_examination: '',
      },
      appointment_details: {},
      examinations_details: [],
      examinations_details_dialog: false,
      examinationDialog: false,
      examinations_signs: [],
      advice_dialog: false,
      comment_dialog: false,
      advice_details_dialog: false,
      comment_details_dialog: false,
      currentPage: 0,
      per_page: 20,
      total_count: 0,
      piraniScoreDialog: false,
      pirani_score_view_dialog: false,
      appointment_update_dailog: false,
    };
  },
  created() {
    this.getAppointments();
  },
  methods: {
    calculateSessionMinutes(start, end) {
      if (!start || !end) {
        return '';
      }
      const startTime = new Date(start);
      const endTime = new Date(end);
      const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      return Math.round(difference / 60000);
    },
    getAppointments(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v2/appointment/list/new?user_type=agent&for_agent=1${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.search ? `&q=${this.search}` : ''}${this.search_status ? `&status=${this.search_status}` : ''}${this.search_type ? `&search_type=${this.search_type}` : ''}&limit=${this.per_page}&offset=${correctValue || 0}${this.center_id ? `&institute_id=${this.center_id}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.tableData = response.data.data;
          this.total_count = response.data.total_count;
          this.loading = false;
        });
    },
    appointmentStatusChanged(id, status) {
      this.action = status;
      this.appointment_id = id;
      this.dialogVisible = true;
    },
    appointmentRescheduled(item) {
      // console.log('item', item)
      this.$router.push({
        path: '/appointments',
        query: {
          doctor_id: item.doctor_id,
          doctor_name: item.doctor_name,
          id: item.patient_id,
          name: item.patient_name,
          appointment_type: 'Reschedule',
          appointment_id: item.id,
        },
      });

      // console.log('call')
      // // console.log('item',item)
      // this.is_appointment_modal = true;
      // this.reschedule_data = item;
    },
    chaneStatus(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          const form = new FormData();
          form.append('comment', this.form.comment);
          form.append('id', this.appointment_id);
          form.append('action_type', 'reject');
          form.append('module_type', 'appointment');
          form.append('status', 'canceled');

          this.$nextTick(() => {
            axios
              .post('/api/v2/appointment/change/status', form)
              .then((response) => {
                if (response.data.status_code == 200) {
                  this.form = {};
                  this.dialogVisible = false;
                  this.$notify({
                    title: 'Success',
                    message: 'Successfully Canceled',
                    type: 'success',
                    duration: 2000,
                  });
                  this.getAppointments();
                } else {
                  this.$notify({
                    title: 'Failed',
                    message: response.data.message,
                    type: 'danger',
                    duration: 2000,
                  });
                }
              });
          });
        } else {
          return true;
        }
      });
    },
    generateInvoice(item) {
      this.invoice_details = item;
      this.dialogInvoice = true;
    },
    sessionStartEnd(item) {
      this.invoice_details = item;
      this.sessionStartEndDialog = true;
    },
    payPayment(payment) {
      this.payment_details = payment;
      this.payment.amount = payment.payable_amount;
      this.paymentDialogVisible = true;
    },
    clear() {
      this.search_type = '';
      this.search = '';
      this.schedule_date = '';
      this.getAppointments();
    },
    makePayment(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          const form = new FormData();
          form.append('request_id', this.payment_details.id);
          form.append('amount', this.payment.amount);
          form.append('is_paid', this.payment.payment_option);
          form.append('cart_type', this.payment.transaction_type);
          // form.append("card_brand", this.payments.banking_name);
          form.append('user_id', this.payment_details.patient_id);
          this.$nextTick(() => {
            axios
              .post(`${process.env.VUE_APP_URL}/api/v2/agent/appointment/payment?api_token=${localStorage.getItem('api_token')}`, form)
              .then((response) => {
                if (response.data.status_code == 200) {
                  this.form = {};
                  this.paymentDialogVisible = false;
                  this.$notify({
                    title: 'Success',
                    message: 'Successfully Paid',
                    type: 'success',
                    duration: 2000,
                  });
                  this.getAppointments();
                } else {
                  this.$notify({
                    title: 'Failed',
                    message: response.data.message,
                    type: 'danger',
                    duration: 2000,
                  });
                }
              });
          });
        } else {
          return false;
        }
      });
    },
    paymentModalClose() {
      this.is_appointment_modal = false;
    },
    searchPatient(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          this.getPatients();
          this.searchDoctorDialog = true;
        } else {
          return true;
        }
      });
    },
    getPatients() {
      this.loading = true;
      axios.get(`/api/v1/patient/subordinate/list?term=${this.search_form.patient_q}`)
        .then((response) => {
          this.patients = response.data.data;
          this.loading = false;
        });
    },
    createNewPatient() {
      this.is_change_modal_open = true;
      this.search_form.patient_q = this.form.phone_number;
    },
    patientAdded() {
      this.is_change_modal_open = false;
      this.getPatients();
    },
    modalClose() {
      this.dialogInvoice = false;
    },
    dialogClose() {
      this.searchDoctorDialog = false;
    },
    selectedPatient(value) {
      this.$router.push(`/appointments?id=${value.id}&name=${value.fullname}`);
    },
    responseUpdated(value) {
      // this.getAppointments();
      this.tableData.map((item) => {
        // console.log('item', item);
        if (item.id == value.id) {
          this.invoice_details.session_started_at = value.session_started_at;
          if (value.session_completed_at) {
            this.getAppointments();
          }
        }
      });
    },

    examinationsModalOpen(item) {
      this.appointment_details = item;
      this.examinationDialog = true;
    },

    piraniScoreModalOpen(item) {
      this.piraniScoreDialog = true;
      this.appointment_details = item;
    },

    examinationsViewModalOpen(item) {
      axios.get(`/api/v2/appointment/${item.id}/medical-examinations`)
        .then((res) => {
          this.examinations_details_dialog = true;
          this.examinations_details = this.groupBy(res.data.data, 'type');
        });
    },

    piraniScoreViewModalOpen(item) {
      axios.get(`/api/v2/appointment/${item.id}/medical-examinations`)
        .then((res) => {
          this.pirani_score_view_dialog = true;
          this.examinations_details = this.groupBy(res.data.data, 'type');
        });
    },

    groupBy(arrr, key) {
      return arrr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    adviceModalOpen(item) {
      this.advice_dialog = true;
      this.appointment_details = item;
    },
    commentModalOpen(item) {
      this.comment_dialog = true;
      this.appointment_details = item;
    },
    adviceDetailsModalOpen(item) {
      this.advice_details_dialog = true;
      this.appointment_details = item;
    },
    commentDetailsModalOpen(item) {
      this.comment_details_dialog = true;
      this.appointment_details = item;
    },
    appointmentStatusUpdate(item, status) {
      this.appointment_update_dailog = true;
      this.appointment_details = item;
    },
    updatedAppontment() {
      this.getAppointments();
      // this.tableData.map((item) => {
      //   // console.log('item', item);
      //   if (item.id == value.id) {
      //     this.appointment_details.session_started_at = value.session_started_at;

      //   }
      // });
      // this.tableData = this.tableData.map(item => {
      //   if(item.id == res.id) {
      //     item = res;
      //   }
      //   return item;
      // })
    },
  },
  title: 'My Appointment',
};
</script>

<style scoped>
h2, h3, h4, p {
  margin: 10px 0px;
  padding: 0;
}

.dashed {
  border: 2px dashed var(--el-border-color);
  padding: 0px 10px 30px 10px;
}

.single_patient {
  border: 1px solid #ddd;
  border-radius: 7px;
  padding: 20px;
  margin: 10px 0px;
}
</style>
<style>
.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}
</style>
