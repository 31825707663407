<template>
  <div>
    <el-dialog
        v-model="comment_details_dialog"
        title="Comments"
        class="appointment-status-modal"
        size="lg">
        <p>{{ comment.comment }}</p>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="comment_details_dialog = false">Cancel</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CommentDetails',
  props: {
    appointment_details: {},
  },
  data() {
    return {
      comment_details_dialog: false,
      comment: {},
    };
  },
  created() {
    this.comment_details_dialog = true;
    if (this.comment_details_dialog) {
      this.getComment();
    }
  },
  methods: {
    getComment() {
      axios.get(`/api/v2/appointment/${this.appointment_details.id}/comments`)
        .then((res) => {
          this.comment = res.data.data;
        });
    },
  },
  watch: {
    comment_details_dialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>

</style>
