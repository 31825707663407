<template>
  <div v-loading.fullscreen.lock="loading">

    <el-row justify="space-between">
      <el-col :sm="24" :md="12" :lg="12" v-if="authUser.user_type == 'executive'">
        <el-select @change="getPatients()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large" clearable>
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :sm="20" :md="12" :lg="12">
        <el-form
            :model="search_form"
            :rules="search_rules"
            label-position="top"
            @submit.prevent="searchPatient('search_form')"
            ref="search_form"
        >
          <div class="d-flex align-items-center">
            <el-form-item label="Search Patient" prop="patient_q" class="w-100">
              <el-input
                  v-model="search_form.patient_q"
                  placeholder="Search Patient Phone Number"
                  class="input-with-select"
                  type="number"
                  min="0"
                  clearable
              >
              </el-input>
            </el-form-item>

            <el-button type="success" class="mt-5 mx-5" @click="searchPatient('search_form')" round>Search</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <el-button type="primary" @click="openModal"  v-if="checkUsrPermissions('patient add')">
      <el-icon>
        <font-awesome-icon icon="fa fa-baby"/>
      </el-icon>
      <span class="mx-10">Create new patient</span>
    </el-button>

    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Phone Number</p>
        <el-input v-model="q" size="large" type="number"/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="mt-40">
        <el-button type="primary" size="large" @click="getPatients" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
    </el-row>
    <br>
    <el-table :data="filteredList" border style="width: 100%">
      <el-table-column prop="fullname" label="Name"/>
      <el-table-column prop="phone_number" label="phone"  v-if="authUser.user_type=='agent-pharmacy' || authUser.user_type=='cashier'">
        <template #default="scope">
          {{ scope.row.phone_number }}
        </template>
      </el-table-column>
      <el-table-column prop="age" label="Age"/>
      <el-table-column label="Is Relapsed?" align="center">
        <template #default="scope">
          <el-tag
                v-if="scope.row.cf_patient_details && scope.row.cf_patient_details.is_relapsed"
                class="mx-1"
                type="success"
                effect="dark"
            >
              Yes
          </el-tag>
          <el-tag
                v-else
                class="mx-1"
                type="warning"
                effect="dark"
            >
              No
          </el-tag>
           
        </template> 
      </el-table-column>
      <el-table-column prop="phase" label="Phase">
        <template #default="scope">
          <div v-if="scope.row.cf_patient_details" class="text-center">
            <el-tag class="ml-2" type="success"
                    v-if="scope.row.cf_patient_details.phase == 0 || scope.row.cf_patient_details.phase == 1 || scope.row.cf_patient_details.phase==2">
              Treatment phase
            </el-tag>
            <el-tag class="ml-2" v-else-if="scope.row.cf_patient_details.phase == 3">Brace followup
            </el-tag>
            <p v-else> n/a </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="last_appointment_date" label="Last appointment">
        <template #default="scope">
          <div v-if="scope.row.cf_patient_details" class="text-center">
            <p class="mt-0 mb-0">{{ scope.row.cf_patient_details.last_appointment_date }}</p>
            <div v-if="scope.row.cf_patient_details.last_appointment_status">
              <el-tag class="ml-2 " type="success"
                      v-if="scope.row.cf_patient_details.last_appointment_status == 'booked'">
                {{ scope.row.cf_patient_details.last_appointment_status }}
              </el-tag>
              <el-tag class="ml-2 " type="danger" v-else>{{
                  scope.row.cf_patient_details.last_appointment_status
                }}
              </el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="actions" class="text-center" label="Actions">
        <template #default="scope">
          <router-link class="m-5" :to="`/patients/${scope.row.id}?zeroclubfoot=zeroclubfoot`" v-if="checkUsrPermissions('User profile')">
            <el-button type="success" circle>
              <el-icon>
                <View/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link class="m-5" :to="`/appointments?id=${scope.row.id}&name=${scope.row.fullname}`">
            <el-button type="info" circle>
              <el-icon>
                <Checked/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link :to="`zero-club-foot-patient/update/${scope.row.id}`" v-if="checkUsrPermissions('store cancer patient pre screening data')">
            <el-button type="primary" circle>
              <el-icon>
                <font-awesome-icon icon="fa fa-edit"/>
              </el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <br>

    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="getPatients"
    />

    <!--  modal  -->
    <div>
      <AddPatient :is_zero_club_foot="true" @getPatients="getPatients" @modalClosed="is_change_modal_open=false"
                  v-if="is_change_modal_open"/>
    </div>
    <el-dialog v-model="searchDoctorDialog" class="appointment-status-modal">
      <div v-if="patients.length">
        <div v-for="(patient, index) in patients" :key="index" class="single_patient d-flex justify-content-between">
          <div>
            <h3 class="mt-0 mb-0">{{ patient.fullname }}</h3>
            <p class="mb-0 mt-0">{{ patient.phone_number }}</p>
          </div>
          <div>
            <router-link :to="`/appointments?id=${patient.id}&name=${patient.fullname}`">
              <el-button type="success">Get Appointment</el-button>

            </router-link>
            <router-link :to="`/zero-club-foot-patient/update/${patient.id}`" v-if="!patient.cf_patient_details" class="mx-5">
              <el-button type="primary">Make ZCF</el-button>
            </router-link>
            <router-link :to="`/zero-club-foot-patient/update/${patient.id}?reg_type=relapse`" v-if="patient.cf_patient_details"
                         class="mx-5">
              <el-button type="primary">Relapse Patient</el-button>
            </router-link>
            <router-link :to="`/patients/${patient.id}?zeroclubfoot=zeroclubfoot`" class="mx-5">
              <el-button type="info">Details</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <el-empty :image-size="100" description="No patient found"/>
        <!--        <el-button type="success" @click="createNewPatient">Create New Patient</el-button>-->
        <PatientForm :is_zero_club_foot="true" :phone_number="search_form.patient_q" @dialogClose="dialogClose"
                     @selectedPatient="selectedPatient"/>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import AddPatient from '@/components/modal/AddPatientModal';
import { CommonFunction } from '@/mixins/CommonFunction';
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import PatientForm from '../../components/form/PatientForm';

export default {
  name: 'FoodClubPatient',
  components: {
    AddPatient,
    PatientForm,
  },
  mixins: [CommonFunction, CenterMixins],
  data() {
    return {
      tableData: [],
      search: '',
      dialogFormVisible: false,
      is_change_modal_open: false,
      loading: false,
      total: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      searchDoctorDialog: false,
      q: '',
      search_form: {
        patient_q: '',
      },
      search_rules: {
        patient_q: [
          {
            required: true,
            min: 11,
            message: 'Please input number',
          },
        ],
      },
      patients: [],
    };
  },
  created() {
    this.getPatients();
  },
  computed: {
    filteredList() {
      return this.tableData.filter(
        (data) => !this.search
              || data.fullname.toLowerCase()
                .includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    getPatients(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v2/partner/agent/cf/patients?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.q ? `&q=${this.q}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total_count;
          this.loading = false;
        });
    },
    getSearchPatients() {
      this.loading = true;
      axios.get(`/api/v1/patient/subordinate/list?term=${this.search_form.patient_q}`)
        .then((response) => {
          this.patients = response.data.data;
          this.loading = false;
        });
    },
    openModal() {
      this.is_change_modal_open = true;
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.q = '';
      this.getPatients();
    },
    searchPatient(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          this.getSearchPatients();
          this.searchDoctorDialog = true;
        } else {
          return true;
        }
      });
    },
    dialogClose() {
      this.searchDoctorDialog = false;
    },
    selectedPatient(value) {
      this.$router.push(`/appointments?id=${value.id}&name=${value.fullname}`);
    },

  },
  title: 'Zero club foot',
};
</script>

<style scoped>

</style>
