<template>
  <div>
    <div v-if="checkUsrPermissions('Add medical examinations by patient_id')">
      <el-button type="primary" class="my-10" @click="createPiraniSocre">Create new pirani score</el-button>
    </div>

    <!-- <el-table :data="items" border>
      <el-table-column prop="title" label="Title"/>
      <el-table-column prop="type" label="Type"/>
      <el-table-column prop="term" label="Score"/>
      <el-table-column prop="sides" label="Sides"/>
      <el-table-column prop="evaluation_date" label="Evaluation date"/>
    </el-table> -->

    <table class="custom_table my-10" v-if="items.length">
      <tbody>
        <tr>
          <td style="padding: 8px 0" class="td_sticky">SL No</td>
          <td style="padding: 8px 0; font-weight: 600;" v-for="index in Object.keys(dateWisePirani).length" :key="index">{{ index }}</td>
        </tr>
        <tr>
          <td style="padding: 8px 0" class="td_sticky">Date Of Evaluation</td>
          <td style="padding: 8px 0" v-for="(piraniData, key) in dateWisePirani" :key="key">{{ key }}</td>
        </tr>
        <tr>
          <td style="padding: 8px 0" class="td_sticky">Foot</td>
          <td v-for="(piraniData, key) in Object.keys(dateWisePirani).length" :key="key">
            <table>
              <tr>
                <td style="font-weight: 600;">R</td>
                <td style="font-weight: 600;">L</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr v-for="type in examinations_signs" :key="type.id">
          <td style="padding: 8px 0" class="td_sticky">{{ type.title }}</td>
          <td v-for="(piraniData, key) in dateWisePirani" :key="key">
            <table>
              <tr>
                <td>{{ findPiraniScore('R', piraniData, type.id) }}</td>
                <td>{{ findPiraniScore('L', piraniData, type.id) }}</td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td style="padding: 8px 0; background-color: #ddd;" class="td_sticky">Total</td>
          <td v-for="(piraniData, key) in dateWisePirani" :key="key">
            <table>
              <tr>
                <td><strong>{{ totalPiraniScore('R', piraniData) }}</strong></td>
                <td><strong>{{ totalPiraniScore('L', piraniData) }}</strong></td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <PiraniScoreModal @closeModal="closeModal" v-if="pirani_dialog" :pirani_patient_profile="pirani_patient_profile"/>
  </div>
</template>

<script>
import axios from 'axios';
import PiraniScoreModal from '@/components/modal/PiraniScoreModal';

export default {
  name: 'PatientPirani',
  components: {
    PiraniScoreModal,
  },
  data() {
    return {
      items: [],
      examinations_signs: [],
      pirani_dialog: false,
      pirani_patient_profile: true,
      dateWisePirani: []
    };
  },
  created() {
    this.getPirani();
    this.getMedicalExaminationItems();
  },
  methods: {
    totalPiraniScore(side, dateWiseData) {
      let sum = 0;
      dateWiseData.forEach((item)=> {
        if(item.sides == side) {
          sum += parseFloat(item.term);
        }
      })
      return sum;
    },
    findPiraniScore(side, dateWiseData, typeId) {
      let piraniScore = dateWiseData.find((item)=> {
        return item.type_id == typeId && item.sides == side;
      })
      return piraniScore?piraniScore.term:' - ';
    },
    getPirani() {
      axios.get(`/api/v2/appointment/${this.$route.params.id}/pirani-scores`)
        .then((res) => {
          this.items = res.data.data;
          this.dateWisePirani = this.groupBy(res.data.data, 'evaluation_date');
          console.log(Object.keys(this.dateWisePirani).length);
        });
    },
    getMedicalExaminationItems() {
      axios.get('/api/v1/medical-general-examination/list?type=pirani')
        .then((res) => {
          this.examinations_signs = res.data.data;
        });
    },
    createPiraniSocre() {
      this.pirani_dialog = true;
      this.pirani_patient_profile = true;
    },
    closeModal() {
      this.pirani_dialog = false;
      this.pirani_patient_profile = false;
      this.getPirani();
    },
    groupBy(arrr, key) {
      return arrr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
};
</script>

<style lang="scss">
table{
  &.custom_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    overflow-x: scroll;
    display: block;
    th, td {
      text-align: center;
      padding: 0;
      border: 1px solid #ddd;
      border-collapse: collapse;
    }
    tr{
      display: flex;
      th{
        width: 100%;
      }
      td{
        width: 100px;
        &.td_sticky{
          position: sticky;
          left: 0;
          background: #fff;
          top: 0;
          width: 200px;
          font-weight: 600;
        }
      }
      table{
        width: 100%;
        tr{
          display: table-row;
          th, td{
            padding: 8px;
            width: 50%;
            font-weight: 500;
          }
        }
      }
    }
  }
}

</style>
