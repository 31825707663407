<template>
  <div>
    <el-dialog
        v-model="advice_dialog"
        title="Advice"
        class="appointment-status-modal"
        size="lg">
      <el-form :model="advice" label-position="top"
               label-width="100px" ref="invoice">
        <el-row :gutter="20" v-for="(item, index) in new_advices" :key="index" align="middle" class="mb-10">
          <el-col :span="8">
            <el-checkbox v-model="item.not_here" size="large">Add new advice</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-radio v-model="item.advice_type" label="text">Text</el-radio>
            <el-radio v-model="item.advice_type"  label="image">Image</el-radio>
            <div v-if="item.advice_type == 'image'">
              <el-dropdown @command="selectedImage($event,item)">
                <span class="el-dropdown-link d-flex item-center custom-padding">
                  <el-avatar v-if="item.selected_image" :size="70" class="mr-5"  :src="userPhoto(item.selected_image)">
                                  <img
                                      src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                                  />
                                </el-avatar>
                  Select Image
                  <el-icon class="el-icon--right ml-5">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu class="custom-dropdown-menu">
                    <el-dropdown-item :command="image"  v-for="(image,index) in imageAdvices" :key="index" >
                      <el-avatar :size="70"  :src="userPhoto(image.title)">
                                  <img
                                      src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                                  />
                                </el-avatar>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div v-else>
              <el-select class="w-100" :fit-input-width="true" v-model="item.id" filterable placeholder="Select Advice" :disabled="item.not_here"
                      v-if="!item.not_here">
                  <el-option
                      v-for="item in textAdvices"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                  />
                  
                 
              </el-select>
              <el-input v-model="item.title" placeholder="Enter Title" v-else/>
            </div>
          </el-col>
          <el-col :span="4" v-if="index != 0">
            <el-button type="danger"  circle class="mt-5" @click="removeItem(index)">
              <el-icon><DeleteFilled /></el-icon>
            </el-button>
          </el-col>
        </el-row>
        <el-button @click="addMoreAdvice">Add</el-button>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="advice_dialog = false" >Cancel</el-button>
                <el-button type="primary" @click="submitAdvice('advice')">
                  Confirm
                </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddAdvice',
  props: {
    appointment_details: {},
  },
  data() {
    return {
      advice_dialog: false,
      advice: {},
      advices: [],
      
      form: {
        advice_id: '',
        not_here: false,
      },
      new_advices: [
        {
          id: '',
          title: '',
          advice_type: '',
        },
      ],
      activeName: 'text',
      selected_image: null,
    };
  },
  created() {
    this.advice_dialog = true;
    if (this.advice_dialog) {
      this.getAdvice();
    }
  },
  computed: {
    textAdvices(){
      return this.advices.filter(item => item.type == 'text');
    },
    imageAdvices(){
      return this.advices.filter(item => item.type == 'image');
    }
  },
  methods: {
    
    getAdvice(type = '') {
      axios.get(`/api/v2/medical-advices/occ`)
        .then((res) => {
          this.advices = res.data.data;
        });
    },

    submitAdvice() {
      this.new_advices.map((advice) => {
        const adviceObject = this.advices.find((item) => item.id == advice.id);
        if (adviceObject) {
          advice.title = adviceObject.title;
        }
        return advice;
      });

      axios.post(`/api/v2/appointment/${this.appointment_details.id}/medical-advices`, { advices: this.new_advices })
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: 'Success',
              message: 'Successfully saved',
              type: 'success',
              duration: 2000,
            });
            this.advice_dialog = false;
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },

    addMoreAdvice() {
      this.new_advices.push({
        id: '',
        title: '',
        advice_type: '',
      });
    },

    removeItem(index) {
      this.new_advices.splice(index, 1);
    },

    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    selectedImage(command, item) {
       item.selected_image = command.title;
       item.id = command.id
    },

  },
  watch: {
    advice_dialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<style scoped>
.item-center {
  align-items: center;
}
.custom-dropdown-menu {
  height: 300px !important;
}
</style>
<style>
.w-100 {
  width: 100%;
}
.select-image {
  height: 100px !important;
}
.option-height {
  height: 80px !important;
}
.custom-padding {
  padding: 10px;
}
.mt-5 {
  margin-top: 25px !important;
}
</style>
