<template>
  <div>
    <el-dialog
        v-model="comment_dialog"
        title="Comments"
        class="appointment-status-modal"
        size="lg">
      <el-form :model="commentForm" label-position="top"
               label-width="100px" ref="comment">
               <el-input
                  v-model="form.comment"
                  :rows="3"
                  type="textarea"
                  placeholder="Write your comments ...."
                />
        
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="comment_dialog = false" >Cancel</el-button>
                <el-button type="primary" @click="submitComment('commentForm')">
                  Confirm
                </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddComment',
  props: {
    appointment_details: {},
  },
  data() {
    return {
      comment_dialog: false,
      form: {
        comment: '',
      },
      activeName: 'text',
    };
  },
  created() {
    this.comment_dialog = true;
  },

  methods: {
    submitComment() {
      axios.post(`/api/v2/appointment/${this.appointment_details.id}/comments`, this.form)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: 'Success',
              message: 'Successfully saved',
              type: 'success',
              duration: 3500,
            });
            this.comment_dialog = false;
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },

  },
  watch: {
    comment_dialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<style scoped>
.item-center {
  align-items: center;
}
.custom-dropdown-menu {
  height: 300px !important;
}
</style>
<style>
.w-100 {
  width: 100%;
}
.select-image {
  height: 100px !important;
}
.option-height {
  height: 80px !important;
}
.custom-padding {
  padding: 10px;
}
.mt-5 {
  margin-top: 25px !important;
}
</style>
