<template>
  <div>
    <el-table :data="items" border style="width: 100%" v-loading="loading">
        <el-table-column prop="fullname" label="Fullname"></el-table-column>

        <el-table-column prop="call_category" label="Call category"></el-table-column>
        <el-table-column prop="call_type" label="Call type"></el-table-column>
        <el-table-column prop="call_action_status" label="Call action status">
            <template #default="scope">
                <el-tag class="ml-2" :type="scope.row.call_action_status == 'pending' ? 'warning' : 'danger'">{{  scope.row.call_action_status }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="remarks" label="Remarks"></el-table-column>
        <el-table-column prop="assigned_crm_name" label="Assigned crm name"></el-table-column>
        <el-table-column prop="call_action_date" label="Called at"></el-table-column>
    </el-table>

    <el-pagination
        :current-page="call_current_page"
        :page-size="perPage"
        layout="total, prev, pager, next"
        :total="total_call"
        @current-change="getCalls"
    />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PatientCallLog',
  data() {
    return {
      perPage: 5,
      total_call: 0,
      items: [],
      loading: false,
      call_current_page: 1,
    };
  },
  created() {
    this.getCalls();
  },
  methods: {
    getCalls(value) {
      this.loading = true;
      if (Number(value)) {
        this.call_current_page = value;
        const offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`/api/v2/agent/call-logs/patients/${this.$route.params.id}?limit=${this.perPage}&offset=${correctValue || 0}`)
        .then((res) => {
          this.items = res.data.data;
          this.total_call = res.data.total_count;
          console.log(this.total_call, 'this.total_call')
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped>

</style>
