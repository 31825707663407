<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" v-if="$route.query.reg_type == 'relapse'" align="right">
        <h3 style="color: red">Relapsed Patient Registration</h3> 
      </el-col>
    </el-row>
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="ক্লাব ফুট রোগী " name="patient_info">
        <el-form :model="general_info" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="রোগীর নাম">
                <el-input v-model="general_info.fullname" disabled/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="রেজিং নং ">
                <el-input v-model="cf_patient_details_info.registration_number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="লিঙ্গ">
                <el-radio-group v-model="general_info.gender" disabled>
                  <el-radio label="male">পুরুষ</el-radio>
                  <el-radio label="female">মহিলা</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="জন্ম তারিখ ">
                <el-date-picker
                    v-model="general_info.dob"
                    type="date"
                    class="w-100"
                    placeholder="Pick date of birth"
                    disabled
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="বাবার নাম ">
                <el-input v-model="cf_patient_details_info.father_name"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="বাবার বয়স ">
                <el-date-picker
                    class="app_date_picker w-100"
                    popper-class="my-datepicker"
                    v-model="cf_patient_details_info.father_age"
                    type="date"
                    placeholder="Pick a day"
                    size="lg"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="মায়ের নাম">
                <el-input v-model="cf_patient_details_info.mother_name"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="মায়ের বয়স">
                <el-date-picker
                    class="app_date_picker w-100"
                    popper-class="my-datepicker"
                    v-model="cf_patient_details_info.mother_age"
                    type="date"
                    placeholder="Pick a day"
                    size="lg"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গ্রাম">
                <el-input v-model="cf_patient_details_info.village"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="পোস্ট অফিস ">
                <el-input v-model="cf_patient_details_info.po"/>
              </el-form-item>
            </el-col>
<!--            <el-col :xs="24" :sm="12" :md="8" :lg="8">-->
<!--              <el-form-item label="থানা/উপজেলা">-->
<!--                <el-input v-model="general_info.thana"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="12" :md="8" :lg="8">-->
<!--              <el-form-item label="জেলা">-->
<!--                <el-input v-model="general_info.district"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="মোবাইল নং (১)">
                <el-input v-model="general_info.phone_number" disabled/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="মোবাইল নং (২)">
                <el-input v-model="cf_patient_details_info.phone_number_2"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="ই-মেইল">
                <el-input v-model="general_info.email"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="পরিবারের কয়জনের মুগুর পা আছে?">
                <el-radio-group v-model="no_of_cf_patient">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
                <el-input v-if="no_of_cf_patient == 1" type="number" :value="cf_patient_details_info.no_of_cf_patient ? cf_patient_details_info.no_of_cf_patient : 0" :minlength="0" :max="10" v-model="cf_patient_details_info.no_of_cf_patient"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" v-if="cf_patient_details_info.relapse_started_at">
              <span style="color: red; font-size: 16px; font-weight: 600;">Relapse Reg: {{ cf_patient_details_info.relapse_started_at }}</span>
            </el-col>
            
            <el-col :xs="24" :sm="24" :md="24" :lg="24">
              <el-form-item label="রেফারেন্স">
                <el-radio-group v-model="cf_patient_details_info.ref_by">
                  <el-radio label="doctor">ডাক্তার</el-radio>
                  <el-radio label="nurse">নার্স</el-radio>
                  <el-radio label="old_patient">পুরাতন রোগী</el-radio>
                  <el-radio label="ngo">এনজিও</el-radio>
                  <el-radio label="hw">স্বাস্থকর্মী</el-radio>
                  <el-radio label="marketing">প্রচারণা</el-radio>
                  <el-radio label="other">অন্যান্য</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <br>
          <div class="text-center">
            <el-button :type="$route.query.reg_type == 'relapse'?'danger':'primary'" class="floting-button" @click="updateCFPatient">{{ $route.query.reg_type == 'relapse'?'Relapse Patient':'Update'}}</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="প্রসব পূর্ববর্তী" name="pregnancy_before">
        <el-form :model="pregnancy_before" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভ ধারণের সর্বমোট সময়">
                <el-radio-group v-model="pregnancy_before.pregnancy_on_time">
                  <el-radio :label="1">সঠিক সময়</el-radio>
                  <el-radio :label="0">সময়ের আগে</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভ ধারণের  সময়">
                <el-input v-model="pregnancy_before.pregnancy_time" :max="20" :min="0" type="number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="প্রসবের সময় মায়ের বয়স">
                <el-input v-model="pregnancy_before.mother_age_during_pregnancy" type="number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="কোন বড় ধরণের অসুখ হয়েছিল">
                <el-radio-group v-model="pregnancy_before.any_major_disease">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">

              <el-form-item label="গর্ভা অবস্থায় কোন সমস্যা  সম্মুখীন হয়েছিল ">
                <el-checkbox-group v-model="prob_during_pregnancy">
                  <el-checkbox label="স্বল্পমাত্রায় খাদ্যগ্রহণ"/>
                  <el-checkbox label="অতিরিক্ত কাজের চাপ"/>
                  <el-checkbox label="মানসিক চাপ"/>
                  <el-checkbox label="শারীরিক  সমস্যা"/>
                  <el-checkbox label="কোনটাই না"/>
                </el-checkbox-group>
              </el-form-item>

            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভাবস্থায় ডায়াবেটিকস ছিল?">
                <el-radio-group v-model="pregnancy_before.has_diabetes">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভাবস্থায় উচ্চরক্তচাপ ছিল?">
                <el-radio-group v-model="pregnancy_before.has_high_bp">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভাবস্থায় কোন টিকা দিয়েছিলেন?">
                <el-radio-group v-model="pregnancy_before.tika">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="আল্ট্রাসনোগ্রাফি করেছিলেন?">
                <el-radio-group v-model="no_of_ultra">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
                <el-input v-if="no_of_ultra==1" v-model="pregnancy_before.no_of_ultra" placeholder="কয় বার"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="আল্ট্রাসোনোগ্রাফিতে কোন সমস্যা ধরা পড়েছিল ?">
                <el-radio-group v-model="pregnancy_before.prob_found_ultra">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="গর্ভকালীন সময় ওষুধ গ্রহণ">
                <!--                <el-radio-group v-model="pregnancy_before.medicine_during_pregnancy">-->
                <!--                  <el-radio label="1">হ্যাঁ</el-radio>-->
                <!--                  <el-radio label="0">না</el-radio>-->
                <!--                </el-radio-group>-->
                <el-checkbox-group v-model="medicine_during_pregnancy">
                  <el-checkbox label="আয়রন"/>
                  <el-checkbox label="জিংক"/>
                  <el-checkbox label="ফলিক এসিড"/>
                  <el-checkbox label="অ্যান্টিবায়োটিক"/>
                  <el-checkbox label="প্যারাসিটামল"/>
                  <el-checkbox label="অন্যান্য"/>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="প্রসবের স্থান">
                <el-radio-group v-model="pregnancy_before.birth_place">
                  <el-radio label="home">নিজ বাড়ি</el-radio>
                  <el-radio label="govt_clinic">স্থানীয় সরকার ক্লিনিক</el-radio>
                  <el-radio label="ngo_clinic">এনজিও পরিচালিত ক্লিনিক</el-radio>
                  <el-radio label="pvt_clinic">প্রাইভেট ক্লিনিক</el-radio>
                  <el-radio label="other">অন্যান্য</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="প্রসবের প্রক্রিয়া ?">
                <el-radio-group v-model="pregnancy_before.normal_birth">
                  <el-radio :label="1">নরমাল</el-radio>
                  <el-radio :label="0">সিজার</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <br>
          <div class="text-center">
            <el-button :type="$route.query.reg_type == 'relapse'?'danger':'primary'" class="floting-button" @click="updateCFPregnancyBefore">{{ $route.query.reg_type == 'relapse'?'Relapse Patient':'Update'}}</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="প্রসব পরবর্তী" name="pregnancy_after">
        <el-form :model="pregnancy_after" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="প্রসবকালীন ও প্রসব পরবর্তী বাচ্চার কোন সমস্যা হয়েছিল কি?">
                <el-radio-group v-model="child_faced_problem">
                  <el-radio label="yes">হ্যাঁ</el-radio>
                  <el-radio label="no">না</el-radio>
                </el-radio-group>
                <el-input v-if="child_faced_problem=='yes'" v-model="pregnancy_after.child_faced_problem"
                          placeholder="Please input"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="জন্মগ্রহনের পর আপনার সন্তান কখন কেঁদেছিলো?">
                <el-radio-group v-model="child_cried_after">
                  <el-radio label="1">স্বাভাবিক</el-radio>
                  <el-radio label="0">দেরীতে</el-radio>
                </el-radio-group>
                <el-input v-if="child_cried_after.includes('0')" v-model="pregnancy_after.child_cried_after"
                          placeholder="কত সময় পর" type="number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="শিশুটি কি আইসিউতে রাখা হয়েছিল?">
                <el-radio-group v-model="child_in_icu">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
                <el-input v-if="child_in_icu==1" v-model="pregnancy_after.child_in_icu" placeholder="কত দিন"
                          type="number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="রোগীর সমস্যা কত বছর বয়সে প্রথম ধরতে পেরেছিলেন?">
                <el-input v-model="pregnancy_after.first_cf_identified" type="number"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="আপনার সন্তান কখন প্রথম হাঁটা শুরু করে?">
                <el-radio-group v-model="first_walked">
                  <el-radio :label="0">স্বাভাবিক</el-radio>
                  <el-radio :label="1">দেরীতে</el-radio>
                </el-radio-group>
                <el-input v-if="first_walked == 1" type="number" v-model="pregnancy_after.first_walked"
                          placeholder="কত সময় পর"/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="বাচ্চার অন্য আর কোন সমস্যা আছে কি?">
                <el-radio-group v-model="child_has_any_problem">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>
                <el-input v-if="child_has_any_problem==1" v-model="pregnancy_after.child_has_any_problem"
                          placeholder="Please input"/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="প্রসবের সময় বাচ্চার ওজন?">
                <el-input v-model="pregnancy_after.weight" type="number" placeholder="Please input"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8">
              <el-form-item label="পূর্বে কোন চিকিৎসা হয়েছিল?">
                <el-radio-group v-model="treatment">
                  <el-radio :label="1">হ্যাঁ</el-radio>
                  <el-radio :label="0">না</el-radio>
                </el-radio-group>

                <el-radio-group v-if="treatment==1" v-model="pregnancy_after.treatment">
                  <el-radio label="গতানুগতিক">গতানুগতিক</el-radio>
                  <el-radio label="পনসেটি">পনসেটি</el-radio>
                  <el-radio label="সার্জারী">সার্জারী</el-radio>
                  <el-radio label="ননপনসেটি">ননপনসেটি</el-radio>
                  <el-radio label="ফিজিওথেরাপী">ফিজিওথেরাপী</el-radio>
                  <el-radio label="ওষুধ">ওষুধ</el-radio>
                  <el-radio label="অন্যান্য">অন্যান্য</el-radio>
                </el-radio-group>

              </el-form-item>

            </el-col>
          </el-row>
          <br>
          <div class="text-center">
            <el-button :type="$route.query.reg_type == 'relapse'?'danger':'primary'" class="floting-button" @click="updateCFPregnancyAfter('pregnancy_after')">{{ $route.query.reg_type == 'relapse'?'Relapse Patient':'Update'}}
            </el-button>

            
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ZeroClubFootRegistration',
  data() {
    return {
      activeName: 'patient_info',
      general_info: {
        fullname: '',
        registration_number: '',
        gender: '',
        phone_number: '',
        dob: null,
      },
      cf_patient_details_info: {
        father_age: '',
        mother_age: '',
        village: '',
        po: '',
        phone_number_2: '',
        no_of_cf_patient: '',
        ref_by: '',
        father_name: '',
        mother_name: '',
      },
      pregnancy_before: {
        pregnancy_on_time: '',
        pregnancy_time: '',
        mother_age_during_pregnancy: '',
        any_major_disease: '',
        prob_during_pregnancy: [],
        has_diabetes: '',
        has_high_bp: '',
        no_of_ultra: '',
        prob_found_ultra: '',
        medicine_during_pregnancy: '',
        tika: '',
        birth_place: '',
        normal_birth: '',
      },
      pregnancy_after: {
        child_faced_problem: '',
        child_cried_after: '',
        child_in_icu: '',
        first_cf_identified: null,
        first_walked: '',
        child_has_any_problem: '',
        treatment: '',
        weight: '',
      },
      loading: false,
      districts: [],
      thanas: [],
      no_of_ultra: '',
      child_faced_problem: '',
      no_of_cf_patient: '',
      child_cried_after: '',
      child_in_icu: '',
      first_walked: '',
      treatment: '',
      child_has_any_problem: '',
      cf_patient_details: false,
      cf_patient_pregnancy_details: false,
      prob_during_pregnancy: [],
      medicine_during_pregnancy: [],
    };
  },
  created() {
    this.getPatientDetails();
  },
  methods: {
    getPatientDetails() {
      this.loading = true;
      axios.get(`/api/v1/user/profile/details/${this.$route.params.id}`)
        .then((response) => {
          // General info
          if (response.data.data) {
            this.general_info = response.data.data;
          }
          // cf basic info
          if (response.data.data.cf_patient_details) {
            this.cf_patient_details_info = response.data.data.cf_patient_details;
          }

          this.cf_patient_details_info.registration_number = this.general_info.registration_number;

          this.cf_patient_details_info.father_name = response.data.data.father_name;
          this.cf_patient_details_info.mother_name = response.data.data.mother_name;
          if (response.data.data.cf_patient_details && response.data.data.cf_patient_details.no_of_cf_patient > 0) {
            this.no_of_cf_patient = 1;
          } else {
            this.no_of_cf_patient = 0;
          }

          if (response.data.data.cf_patient_pregnancy_details && response.data.data.no_of_ultra > 0) {
            this.no_of_ultra = 1;
          } else {
            this.no_of_ultra = 0;
          }

          if (response.data.data.cf_patient_pregnancy_details && response.data.data.cf_patient_pregnancy_details.medicine_during_pregnancy) {
            this.medicine_during_pregnancy = response.data.data.cf_patient_pregnancy_details.medicine_during_pregnancy.split(',');
          }
          if (response.data.data.cf_patient_pregnancy_details && response.data.data.cf_patient_pregnancy_details.prob_during_pregnancy) {
            this.prob_during_pregnancy = response.data.data.cf_patient_pregnancy_details.prob_during_pregnancy.split(',');
          }
          if (response.data.data.cf_patient_pregnancy_details) {
            this.cf_patient_pregnancy_details = true;
            // pregnancy before
            this.pregnancy_before = response.data.data.cf_patient_pregnancy_details;
            // pregnancy after
            if (response.data.data.cf_patient_pregnancy_details.child_in_icu > 0) {
              this.child_in_icu = 1;
            } else {
              this.child_in_icu = 0;
            }
            if (response.data.data.cf_patient_pregnancy_details.first_walked > 0) {
              this.first_walked = 1;
            } else {
              this.first_walked = 0;
            }
            if (response.data.data.cf_patient_pregnancy_details.child_has_any_problem) {
              this.child_has_any_problem = 1;
            } else {
              this.child_has_any_problem = 0;
            }
            this.pregnancy_after = {
              child_faced_problem: response.data.data.cf_patient_pregnancy_details.child_faced_problem,
              child_cried_after: response.data.data.cf_patient_pregnancy_details.child_cried_after,
              child_in_icu: response.data.data.cf_patient_pregnancy_details.child_in_icu,
              first_cf_identified: response.data.data.cf_patient_pregnancy_details.first_cf_identified,
              first_walked: response.data.data.cf_patient_pregnancy_details.first_walked,
              child_has_any_problem: response.data.data.cf_patient_pregnancy_details.child_has_any_problem,
              treatment: response.data.data.cf_patient_pregnancy_details.treatment,
              weight: response.data.data.cf_patient_pregnancy_details.weight,
            };

            if (response.data.data.cf_patient_pregnancy_details.child_has_any_problem) {
              this.child_faced_problem = 'no';
              this.pregnancy_after.child_faced_problem = '';
            }
            if (response.data.data.cf_patient_pregnancy_details.child_cried_after == 1) {
              this.child_cried_after = response.data.data.cf_patient_pregnancy_details.child_cried_after.toString();
            }
          }
          this.loading = false;
        });
    },

    updateCFPatient() {
      let url = '';
    
      if(this.$route.query.reg_type == 'relapse') {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}&relapsed=1`;
      }
      else if (this.cf_patient_pregnancy_details) {
        url = `api/v1/patient/cf/details/update?user_id=${this.$route.params.id}`;
      }
      else {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}`;
      }
      if (!this.no_of_cf_patient) {
        this.cf_patient_details_info.no_of_cf_patient = 0;
      }
      axios.post(url, this.cf_patient_details_info)
        .then((res) => {
          if (res.data.status_code == 200) {
            if(this.$route.query.reg_type == 'relapse') {
              this.$router.replace(`/zero-club-foot-patient/update/${this.$route.params.id}`);
            }
            
            this.getPatientDetails();
            this.$notify({
              title: 'Success',
              message: 'Succesfully Updated',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },

    updateCFPregnancyBefore() {
      let url = '';

      if(this.$route.query.reg_type == 'relapse') {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}&is_relapsed=1`;
      }
      else if (this.cf_patient_pregnancy_details) {
        url = `api/v1/patient/cf/details/update?user_id=${this.$route.params.id}`;
      }
      else {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}`;
      }

      if (!this.no_of_ultra) {
        this.pregnancy_before.no_of_ultra = 0;
      }
      if (this.prob_during_pregnancy) {
        this.pregnancy_before.prob_during_pregnancy = this.prob_during_pregnancy.join();
      }
      if (this.medicine_during_pregnancy) {
        this.pregnancy_before.medicine_during_pregnancy = this.medicine_during_pregnancy.join();
      }
      axios.post(url, this.pregnancy_before)
        .then((res) => {
          if (res.data.status_code == 200) {
            if(this.$route.query.reg_type == 'relapse') {
              this.$router.replace(`/zero-club-foot-patient/update/${this.$route.params.id}`);
            }
            this.getPatientDetails();
            this.$notify({
              title: 'Success',
              message: 'Succesfully Updated',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },

    updateCFPregnancyAfter() {
      let url = '';
      if(this.$route.query.reg_type == 'relapse') {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}&is_relapsed=1`;
      }
      else if (this.cf_patient_pregnancy_details) {
        url = `api/v1/patient/cf/details/update?user_id=${this.$route.params.id}`;
      }
      else {
        url = `api/v1/patient/cf/registration?user_id=${this.$route.params.id}`;
      }

      if (this.child_faced_problem == 'no') {
        this.pregnancy_after.child_faced_problem = 'no';
      }

      if (this.child_cried_after == 1) {
        this.pregnancy_after.child_cried_after = 1;
      }
      if (!this.child_in_icu) {
        this.pregnancy_after.child_in_icu = 0;
      }
      if (!this.child_has_any_problem) {
        this.pregnancy_after.child_has_any_problem = 0;
      }
      if (!this.treatment) {
        this.pregnancy_after.treatment = 0;
      }

      parseInt(this.pregnancy_after.first_cf_identified);

      axios.post(url, this.pregnancy_after)
        .then((res) => {
          if (res.data.status_code == 200) {
            if(this.$route.query.reg_type == 'relapse') {
              this.$router.replace(`/zero-club-foot-patient/update/${this.$route.params.id}`);
            }
            this.getPatientDetails();
            this.$notify({
              title: 'Success',
              message: 'Succesfully Updated',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },

  },
};
</script>
<style scoped>
.floting-button {
  position: fixed;
  bottom: 65px;
  right: 25px;
  margin: 15px;
  /*height: 70px;*/
  /*width: 70px;*/
  /*border-radius: 100px;*/
  cursor: pointer;
  z-index: 9999;
}
</style>
<style>
.w-100 {
  width: 100% !important;
}
</style>
