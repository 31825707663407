<template>
  <el-card class="card-box">
    <div v-loading.fullscreen.lock="loading">

      <div class="d-flex justify-content-between align-items-center">
        <h3>Appointments {{ $route.query.appointment_type ? $route.query.appointment_type : '' }}</h3>
        <div class="mx-30 d-flex" v-if="patient_info">
          <el-avatar class="user_image" :size="40" v-if="patient_info.image && patient_info.image != '/storage/'">
            <img
                :src="userPhoto(patient_info.image)" alt="Orko Health Ltd"
            />
          </el-avatar>
          <el-avatar :size="40" v-else>
            <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Orko"
            />
          </el-avatar>
          <div class="mx-10">
            <p class="mb-0 mt-0">{{ patient_info.fullname }}</p>
            <p class="mb-0 mt-0">{{ patient_info.phone_number }}</p>
               <p class="mb-0 mt-0">{{ patient_info.age }}</p>
          </div>
          <el-button @click="patientRemove" circle>
            <el-icon>
              <Close/>
            </el-icon>
          </el-button>
        </div>
      </div>

      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="Center" name="center">
          <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">

              <el-checkbox v-model="isToday" label="Today" size="large" @change="filterUsers"/>

              <el-card class="box-card" v-for="doc in doctors" :key="doc.id" style="margin-bottom: 10px;">
                <el-row>
                  <el-col :md="4" :sm="6" :xs="8">
                    <el-image style="width: 100px; height: 100px" :src="userPhoto(doc.profile_image)" fit="cover"/>
                  </el-col>
                  <el-col :md="18" :sm="15" :xs="12">
                    <ul class="doctor-list ml-30">
                      <li>{{ doc.prefix }} {{ doc.username }}</li>
                      <li>{{ doc.headline }}</li>
                      <li>{{ doc.speciality ? doc.speciality + ', ' : '' }} Experience: <strong>{{
                          doc.year_of_exp
                        }}</strong> years
                      </li>
                      <li>Rating: <strong>{{ doc.review_score }}</strong></li>
                      <li v-if="doc.service_fee">
                        <el-tag type="success" v-if="doc.service_fee.visit_fee > 0">Visit Fee:
                          <b>{{ doc.service_fee.visit_fee }}</b></el-tag>
                        <el-tag class="ml-5"  v-if="doc.service_fee.call_fee > 0">Virtual Fee:
                          <b>{{ doc.service_fee.call_fee }}</b></el-tag>
                        <el-tag  class="ml-5"  v-if="doc.service_fee.report_fee > 0">Report
                          Fee:<b>{{ doc.service_fee.report_fee }}</b></el-tag>
                        <el-tag class="ml-5"  v-if="doc.service_fee.followup_fee > 0">Followup
                          Fee:<b>{{ doc.service_fee.followup_fee }}</b></el-tag>
                      </li>
                    </ul>
                  </el-col>
                  <el-col :md="2" :sm="3" :xs="4" style="display: flex;align-items: center;">
                    <el-button type="success" @click="doctorDetails(doc)">
                      <el-icon>
                        <View/>
                      </el-icon>
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>

          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Other" name="other">
          <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                  <el-select v-model="medical_service" filterable clearable placeholder="Select" class="mb-10">
                    <el-option
                        v-for="item in medical_services"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    />
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="mt-4 mb-10">
                    <el-input v-model="search_doctor" placeholder="Search Doctor" clearable>
                    </el-input>
                  </div>
                </el-col>
                <el-col :span="4">
                  <el-button type="success" @click="searchDoctor">
                    <el-icon>
                      <Right/>
                    </el-icon>
                  </el-button>
                </el-col>
              </el-row>

              <br>
              <div v-for="(doc, index) in search_doctor_list" :key="index">
                <br>
                <el-card class="box-card">
                  <div class="d-flex justify-content-between align-items-center border">
                    <UserCard :name="doc.details.name" phone_number=""
                              :image="doc.details.image">
                      <p class="mb-0 mt-0 font-size-12">{{ doc.details.card_intro }}</p>
                      <p class="mb-0 mt-0 font-size-12">Visit Fee: {{ doc.details.visit_fee }}</p>
                      <p class="mb-0 mt-0 font-size-12">Virtual Fee: {{ doc.details.call_fee }}</p>
                    </UserCard>
                    <div>
                      <el-button type="success" @click="doctorDetails(doc)">
                        <el-icon>
                          <View/>
                        </el-icon>
                      </el-button>
                    </div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <AddPatient @modalClosed="patientAdded" @selectedPatient="selectedPatient" v-if="is_change_modal_open"/>
      <!--  Show doctor details  -->
      <el-dialog v-model="doctor_details_modal" title="Doctor Details" class="doctor_booking_modal">
        <el-row :gutter="20">
          <el-col :span="12">
            <h3 class="mb-0 mt-0">{{ doctorInformation.fullname }}</h3>
            <p class="mb-0 mt-0"> {{ doctorInformation.phone_number }}</p>
            <p class="mb-0 mt-0"> {{ doctorInformation.location }}</p>
          </el-col>
          <el-col :span="12" v-if="doctorInformation.doctor_information">
            <p class="mb-0 mt-0"><b>Degree:</b> {{ doctorInformation.doctor_information.card_intro }}</p>
            <p class="mb-0 mt-0">Profession: {{ doctorInformation.doctor_information.profession_name }}</p>
            <p class="mb-0 mt-0">Medical Service: {{ doctorInformation.doctor_information.nature_of_service_name }}</p>
            <p class="mb-0 mt-0">Speciality : {{ doctorInformation.doctor_information.speciality_name }}</p>
          </el-col>
        </el-row>
        <br>
        <el-collapse v-model="activeCollapseNames">
          <el-collapse-item title="Overview" name="1">
            <div v-if="doctorInformation.doctor_information">
              <el-card v-for="(item, index) in doctorInformation.doctor_information.workplace" :key="index"
                       style="margin-bottom: 5px;">
                <div>
                  <h4 class="mb-0 mt-0">Address: {{ item.address }}</h4>
                  <p class="mb-0 mt-0">Name: {{ item.name }}</p>
                </div>
                <br>
                <div v-for="(slot, index) in item.chamber_schedules" :key="index" v-if="item.chamber_schedules">
                  {{ slot.day }} {{ slot.chamber_start_time }} {{ slot.chamber_close_time }} {{ slot.time_per_slot }}
                  {{ slot.time_per_slot_unit }}
                </div>

              </el-card>
            </div>
          </el-collapse-item>
        </el-collapse>
        <br>
        <el-form-item label="Appointment type">
          <el-radio-group v-model="form.service_type">
            <el-radio label="visit"/>
            <el-radio label="virtual" @input="clickVirtual"/>
          </el-radio-group>
        </el-form-item>
        <el-row justify="center">
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100">
                <el-date-picker
                    class="app_date_picker"
                    popper-class="my-datepicker"
                    v-model="form.date_value"
                    type="date"
                    placeholder="Pick a day"
                    :size="size"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @change="clickDate"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row justify="center">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div v-if="chambers && form.date_value">
              <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="form.slot_value"/>
            </div>
          </el-col>
        </el-row>
        <br>
        <div v-if="form.date_value">
          <el-row justify="center">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div class="new_patient_section d-flex justify-content-between align-items-center">
                <div class="w-100">
                  <el-select class="w-100" v-model="form.patient_id" filterable placeholder="Select">
                    <el-option
                        v-for="item in patients"
                        :key="item.id"
                        :label="item.fullname"
                        :value="item.id"
                    />
                  </el-select>
                </div>
                <div class="mx-5" v-if="!patient_info">
                  <div>
                    <el-button type="primary" @click="addNewPatient">Create New Patient</el-button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <br>
          <!--      <AppointDetails :doctor="doctor" :date_value="form.date_value" :slot="form.slot_value"/>-->
          <br>
          <div class="text-center" v-if="form.patient_id">
            <el-button @click="confirmAppointmentOtherDoctor" type="primary" round>Confirm Appointment</el-button>
          </div>
        </div>

      </el-dialog>

      <!--    invoice -->
      <InvoiceModal v-if="other_appointment_invoice_modal" :invoice_details="invoice_details" @modalClose="modalClose"/>

      <!--    sidebar -->
      <el-drawer
          v-model="drawer"
          title="Doctor Appointment"
          class="drawer-text custom-el-drawer"
          :direction="direction"
          :with-header="true">
        <el-tabs v-model="drawarTab" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="Appointment" name="appointment">
            <el-form-item label="Service type">
              <el-radio-group v-model="form.service_type">
                <el-radio label="visit" @input="clickVisit"/>
                <el-radio label="virtual" @input="clickVirtual"/>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Appointment type" v-if="doctorInformation.sub_type == 'doctor'">
              <el-radio-group v-model="form.appointment_type">
                <el-radio label="booked_appointment">Booked Appointment (Consultation)</el-radio>
                <el-radio label="clubfoot_appointment">Clubfoot Appointment</el-radio>
                <el-radio label="therapy_appointment">Therapy Appointment</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Appointment type" v-if="doctorInformation.sub_type == 'physio'">
              <el-radio-group v-model="form.appointment_type">
                <el-radio label="booked_appointment">Booked Appointment (Consultation)</el-radio>
                <el-radio label="clubfoot_appointment">Clubfoot Appointment</el-radio>
                <el-radio label="therapy_appointment">Therapy Appointment</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-row justify="center">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-100">
                    <el-date-picker
                        class="app_date_picker"
                        popper-class="my-datepicker"
                        v-model="form.date_value"
                        type="date"
                        placeholder="Pick a day"
                        :size="size"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        @change="clickDate"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row justify="center">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div v-if="chambers && form.date_value">
                  <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="form.slot_value"
                                    @slotNull="formDataNull"/>
                </div>
              </el-col>
            </el-row>
            <br>
            <div v-if="form.date_value">
              <el-row justify="center">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="new_patient_section d-flex justify-content-between align-items-center">
                    <div class="w-100">
                      <el-select
                          v-model="form.patient_id"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Please enter phone number"
                          :remote-method="searchGlobalPatient"
                          :loading="loading"
                          class="w-100"
                      >
                        <el-option
                            v-for="item in patients"
                            :key="item.id"
                            :label="item.fullname"
                            :value="item.id"
                        />
                      </el-select>
                    </div>
                    <div class="mx-5" v-if="!patient_info">
                      <div>
                        <el-button type="primary" @click="addNewPatient">Create New Patient</el-button>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <br>
              <!--      <AppointDetails :doctor="doctor" :date_value="form.date_value" :slot="form.slot_value"/>-->
              <br>
              <div class="text-center" v-if="form.patient_id">
                <el-button @click="confirmAppointmentOtherDoctor" type="primary" round>Confirm Appointment</el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Doctor Details" name="doctor_details">
            <DoctorDetails :doctor_details="doctorInformation"/>
          </el-tab-pane>
        </el-tabs>
      </el-drawer>
    </div>
  </el-card>
</template>

<script>
import axios from 'axios';
import ChamberWithSlots from '../../components/ChamberWithSlots';
import AppointDetails from '../../components/AppointmentDetails';
import AddPatient from '../../components/modal/AddPatientModal';
import UserCard from '../../components/core/UserCard';
import DoctorDetails from '../../components/doctor-component/DoctorDetailsComponent';
import InvoiceModal from '../../components/modal/InvoiceModal';

export default {
  name: 'AppointmentPage',
  components: {
    ChamberWithSlots,
    AppointDetails,
    AddPatient,
    UserCard,
    DoctorDetails,
    InvoiceModal,
  },
  data() {
    return {
      doctors: [],
      patients: [],
      isToday: true,
      form: {
        date_value: '',
        service_type: 'visit',
        patient_id: this.$route.query.name ? this.$route.query.name : '',
        slot_value: '',
        workplace_id: '',
        doctor_id: this.$route.query.doctor_name ? this.$route.query.doctor_name : '',
        working_schedule_time_slot: '',
        appointment_type: '',
      },
      shortcuts: [
        {
          text: 'Today',
          value: new Date(),
        },
        {
          text: 'Yesterday',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
        {
          text: 'A week ago',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          },
        },
      ],
      size: 'large',
      is_change_modal_open: false,
      loading: false,
      chambers: [],
      doctor: {},
      doctor_type: 'doctor',
      doctor_types: [
        {
          text: 'Doctor',
          value: 'doctor',
        },
        {
          text: 'Physio',
          value: 'physio',
        },
      ],
      activeName: 'center',
      search_doctor: '',
      search_doctor_list: [],
      medical_services: [],
      medical_service: '',
      doctorInformation: {},
      doctor_details_modal: false,
      activeCollapseNames: 1,
      other_doctor_id: '',
      invoice_details: {},
      other_appointment_invoice_modal: false,
      my_appointments: [],
      drawer: false,
      direction: 'rtl',
      drawarTab: 'appointment',
      patient_info: {},
    };
  },
  computed: {
    filterDoctor() {
      return this.doctors.filter((doctor) => doctor.sub_type == this.doctor_type);
    },
  },
  created() {
    this.getDoctors();
    this.getPatients();
    this.medicalService();
    if (this.$route.query.appointment_type == 'Reschedule') {
      // this.form.doctor_id = this.$route.query.doctor_id
      this.profile();
    }
    if (this.$route.query.id) {
      this.patientDetails();
    } else {
      this.patient_info = false;
    }
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    addMinutes(time, minsToAdd) {
      function D(J) {
        return (J < 10 ? '0' : '') + J;
      }

      const piece = time.split(':');
      const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

      return `${D(mins % (24 * 60) / 60 | 0)}:${D(mins % 60)}`;
    },
    getDoctors() {
      this.loading = true;
      axios.get(`/api/v2/doctor/center-wise/list?appointment=yes${this.isToday ? '&has_schedule_today=yes' : ''}`)
        .then((response) => {
          this.doctors = response.data.data;
          this.loading = false;
        });
    },
    filterUsers() {
      this.getDoctors();
    },
    profile() {
      this.chambers = {};
      this.form.date_value = '';
      // this.form.patient_id = '';
      this.loading = true;
      axios.get(`/api/v1/user/profile/details/${this.$route.query.doctor_id ? this.$route.query.doctor_id : this.form.doctor_id}?platform=backoffice`)
        .then((res) => {
          this.doctor = res.data.data;
          this.loading = false;
        });
    },
    getPatients() {
      this.loading = true;
      axios.get('/api/v1/patient/subordinate/list')
        .then((response) => {
          this.patients = response.data.data;
          this.loading = false;
        });
    },
    selectedPatient(selectedPatient) {
      this.form.patient_id = selectedPatient.id;
    },
    workPlaceId(id) {
      this.form.workplace_id = id.id;
      this.form.working_schedule_time_slot = id.working_schedules[0].time_per_slot;
    },
    clickDate() {
      this.chambers = [];
      this.loading = true;
      let doctor_id = '';
      if (this.doctorInformation.id) {
        doctor_id = this.doctorInformation.id;
      } else if (this.$route.query.doctor_id) {
        doctor_id = this.$route.query.doctor_id;
      } else {
        doctor_id = this.form.doctor_id;
      }
      const url = `/api/v1/doctor/chamber/list?schedule_date=${this.form.date_value}&doctor_id=${doctor_id}${this.form.service_type == 'visit' ? '' : '&type=telemedicine'}`;
      axios
        .get(url)
        .then((res) => {
          this.chambers = res.data.data;
          this.loading = false;
        });
    },
    addNewPatient() {
      this.is_change_modal_open = true;
    },
    getAppointments() {
      this.loading = true;
      const url = `/api/v2/appointment/list?user_type=agent&for_agent=1${this.schedule_date ? `&schedule_date=${this.schedule_date}` : ''}${this.search ? `&q=${this.search}` : ''}${this.search_type ? `&search_type=${this.search_type}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.my_appointments = response.data.data;
          this.loading = false;
        });
    },
    // checkOtherAppointment() {
    //   let isExist = false;
    //   for (var i = 0; i < this.my_appointments.length; i++) {
    //     if ((this.my_appointments[i].patient_id == this.form.patient_id || this.my_appointments.patient_id == this.$route.query.id) && this.my_appointments[i].doctor_id == this.doctorInformation.id && (this.my_appointments[i].appointment_status == 'booked' || this.my_appointments[i].appointment_status == 'rescheduled')) {
    //       isExist = true;
    //       break;
    //     }
    //   }
    //   return isExist;
    // },
    confirmAppointment() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/appointment/list?user_type=patient${this.$route.query.id ? `&fnf_id=${this.$route.query.id}` : `&fnf_id=${this.form.patient_id}`}&doctor_id=${this.$route.query.doctor_id ? this.$route.query.doctor_id : this.form.doctor_id}`)
        .then((res) => {
          if (res.data.data.length && this.$route.query.appointment_type != 'Reschedule') {
            this.$notify({
              title: 'Failed',
              message: 'Appointment Already Exits',
              type: 'danger',
              duration: 2000,
            });
            this.loading = false;
          } else {
            this.loading = true;
            const form = new FormData();
            form.append('schedule_date', this.form.date_value);
            form.append('schedule_started_at', this.form.slot_value);
            form.append('workplace_id', this.form.workplace_id);
            form.append('doctor_id', this.$route.query.doctor_id ? this.$route.query.doctor_id : this.form.doctor_id);
            form.append('schedule_end_at', this.addMinutes(this.form.slot_value, this.form.working_schedule_time_slot));
            if (this.$route.query.appointment_type == 'Reschedule') {
              form.append('type', 'appointment_booking_reschedule_request_sent_patient');
              form.append('booked_appointment_id', this.$route.query.appointment_id);
            } else {
              form.append('type', 'appointment_booking_request_sent');

              form.append('appointment_status', 'booked');
              form.append('payable_amount', this.doctor.doctor_information.service_fee.visit_fee);
            }
            form.append('service_type', 'visit');
            form.append('patient_id', this.$route.query.id ? this.$route.query.id : this.form.patient_id);
            form.append('api_version', 'v2');
            let url = '';
            if (this.$route.query.appointment_type == 'Reschedule') {
              url = `${process.env.VUE_APP_URL}/api/v2/appointment/reschedule`;
            } else {
              url = `${process.env.VUE_APP_URL}/api/v2/appointment/book`;
            }
            axios
              .post(url, form)
              .then((res) => {
                this.loading = false;
                if (res.data.status_code == 200) {
                  this.form = {
                    service_type: 'visit',
                  };
                  this.$router.push('/my/appointments');
                  this.getAppointments();
                  this.$notify({
                    title: 'Success',
                    message: 'Successfully Appointment created',
                    type: 'success',
                    duration: 2000,
                  });
                } else {
                  this.$notify({
                    title: 'Failed',
                    message: 'Appointment Failed',
                    type: 'danger',
                    duration: 2000,
                  });
                }
              });
          }
        });
    },
    confirmAppointmentOtherDoctor() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/appointment/list?user_type=patient${this.$route.query.id ? `&fnf_id=${this.$route.query.id}` : `&fnf_id=${this.form.patient_id}`}&doctor_id=${this.$route.query.doctor_id ? this.$route.query.doctor_id : this.doctorInformation.id}`)
        .then((res) => {
          if (res.data.data.length && this.$route.query.appointment_type != 'Reschedule') {
            this.$notify({
              title: 'Failed',
              message: 'Appointment Already Exits',
              type: 'danger',
              duration: 2000,
            });
            this.loading = false;
          } else {
            this.loading = true;
            const form = new FormData();
            form.append('schedule_date', this.form.date_value);
            form.append('schedule_started_at', this.form.slot_value);
            form.append('workplace_id', this.form.workplace_id);
            form.append('doctor_id', this.$route.query.doctor_id ? this.$route.query.doctor_id : this.form.doctor_id);
            form.append('schedule_end_at', this.addMinutes(this.form.slot_value, this.form.working_schedule_time_slot));
            if (this.$route.query.appointment_type == 'Reschedule') {
              form.append('type', 'appointment_booking_reschedule_request_sent_patient');
              form.append('booked_appointment_id', this.$route.query.appointment_id);
            } else {
              form.append('type', 'appointment_booking_request_sent');

              form.append('payable_amount', this.doctorInformation.doctor_information ? this.doctorInformation.doctor_information.service_fee.visit_fee : '');

              if (this.doctorInformation.sub_type == 'physio') {
                form.append('appointment_type', this.form.appointment_type);
              } else if (this.doctorInformation.sub_type == 'doctor') {
                form.append('appointment_type', this.form.appointment_type);
              } else if (this.doctorInformation.sub_type == 'dentist') {
                form.append('appointment_type', 'dental_appointment');
              }

              // else if(this.doctorInformation.sub_type == 'physio_assistant') {
              //   form.append('appointment_type', 'therapy_session');
              //   form.append('payable_amount', 1500);
              // }

              else {
                form.append('appointment_type', 'booked_appointment');
              }
              // form.append('appointment_type', 'booked_appointment');
              form.append('appointment_status', 'booked');
            }
            form.append('service_type', 'visit');
            form.append('patient_id', this.$route.query.id ? this.$route.query.id : this.form.patient_id);
            form.append('api_version', 'v2');
            let url = '';
            if (this.$route.query.appointment_type == 'Reschedule') {
              url = `${process.env.VUE_APP_URL}/api/v2/appointment/reschedule`;
            } else {
              url = `${process.env.VUE_APP_URL}/api/v2/appointment/book`;
            }

            axios
              .post(url, form)
              .then((res) => {
                this.loading = false;
                if (res.data.status_code == 200) {
                  this.form = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Successfully Appointment created',
                    type: 'success',
                    duration: 2000,
                  });
                  this.doctor_details_modal = false;
                  this.invoice_details = res.data.data;
                  this.other_appointment_invoice_modal = true;
                  this.$router.push('/my/appointments');
                } else {
                  this.$notify({
                    title: 'Failed',
                    message: 'Appointment Failed',
                    type: 'danger',
                    duration: 2000,
                  });
                }
              });
          }
        });
    },
    patientAdded() {
      this.is_change_modal_open = false;
      this.getPatients();
    },
    searchDoctor() {
      this.loading = true;
      axios.get(`/api/v1/search/information?type=user${this.search_doctor ? `&searched_text=${this.search_doctor}` : ''}${this.medical_service ? `&profession_id=${this.medical_service}` : ''}&type=user`)
        .then((response) => {
          this.search_doctor_list = response.data.data;
          this.loading = false;
        });
    },
    connectDoctor(doctor) {
      this.loading = true;
      const form = new FormData();
      form.append('connected_to', doctor.category_id);
      form.append('api_version', 'v1');
      form.append('is_patient', 0);
      axios.post('/api/v1/doctor/connection/add', form)
        .then((response) => {
          if (response.data.status == 200) {
            this.$notify({
              title: 'Success',
              message: 'Doctor Successfully Connected',
              type: 'success',
              duration: 2000,
            });
            this.getDoctors();
          } else {
            this.$notify({
              title: 'Failed',
              message: response.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
          this.loading = false;
        });
    },
    clickVirtual() {
      this.form.date_value = '';
    },
    clickVisit() {
      this.form.date_value = '';
    },
    medicalService() {
      axios.get('/api/v1/medical/services/list')
        .then((response) => {
          this.medical_services = response.data.data;
        });
    },
    doctorDetails(doctor) {
      this.doctorInformation = {};
      this.form = {
        date_value: this.todayDate(),
        service_type: 'visit',
        patient_id: this.$route.query.name ? this.$route.query.name : '',
        slot_value: '',
        workplace_id: '',
        doctor_id: this.$route.query.doctor_name ? this.$route.query.doctor_name : '',
        working_schedule_time_slot: '',
      };

      const docId = doctor.category_id ? doctor.category_id : doctor.id;
      axios.get(`api/v1/user/profile/details/${docId}`)
        .then((response) => {
          this.doctorInformation = response.data.data;
        });
      this.drawer = true;
      if (this.form.date_value) {
        this.form.doctor_id = docId;
        this.clickDate();
      }
    },
    patientDetails() {
      axios.get(`api/v1/user/profile/details/${this.$route.query.id}`)
        .then((response) => {
          this.patient_info = response.data.data;
        });
    },
    disabledDate(time) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date;
    },
    searchGlobalPatient(value) {
      axios.get(`/api/v1/patient/subordinate/list?term=${value}`)
        .then((response) => {
          this.patients = response.data.data;
        });
    },
    checkDoctorPhysio() {
      this.form = {
        date_value: '',
        service_type: 'visit',
        patient_id: this.$route.query.name ? this.$route.query.name : '',
        slot_value: '',
        workplace_id: '',
        doctor_id: this.$route.query.doctor_name ? this.$route.query.doctor_name : '',
      };
    },
    modalClose() {
      this.other_appointment_invoice_modal = false;
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    patientRemove() {
      this.$router.push('/appointments');
      this.form.patient_id = null;
      this.patient_info = false;
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
      return date;
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
    formDataNull() {
      this.form.slot_value = '';
    },
    handleClick() {

    },

  },
  title: 'Appointments',
};
</script>

<style lang="scss" scoped>
.doctor-list {
  padding: 0;
  list-style-type: none;
  font-size: 15px;

  li {
    &:first-child {
      font-size: 17px;
      font-weight: 600;
    }
  }
}
</style>
<style scoped>
.app_date_picker {
  width: 100% !important;
}

.el-date-editor.el-input__inner {
  width: 100% !important;
}

.font-size-12 {
  font-size: 12px;
}

.patient_info {
  font-size: 18px;
  font-weight: bold;
}
</style>
<style>
.app_date_picker {
  width: 100% !important;
}

</style>
