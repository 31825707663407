<template>
  <div>
    <el-dialog
        v-model="generateInvoiceDialog"
        title="Session Status Update"
        class="appointment-status-modal"
        size="lg">
      <el-form :model="session" label-position="top"
               label-width="100px" ref="invoice">
        <div class="d-flex justify-content-between">
          <div>
            <h4 class="mt-0">Patient Info</h4>
            <UserCard v-if="invoice_details"
                      :name="invoice_details.patient_name"
                      :phone_number="invoice_details.patient_phone"
                      :image="invoice_details.patient_image"></UserCard>
          </div>
          <div>
            <h4 class="mt-0">Doctor Info</h4>
            <UserCard v-if="invoice_details"
                      :name="invoice_details.doctor_name"
                      :phone_number="invoice_details.doctor_phone_number"
                      :image="invoice_details.doctor_image"></UserCard>
          </div>
        </div>
        <br>
<!--        <table v-if="invoice_details.therapies">-->
<!--          <tr>-->
<!--            <th>Therapy name</th>-->
<!--            <th>Quantity</th>-->
<!--            <th>price</th>-->
<!--          </tr>-->
<!--          <tr v-for="(item,index) in invoice_details.therapies" :key="index">-->
<!--            <td>{{ item.therapy_name }}</td>-->
<!--            <td>1</td>-->
<!--            <td>1500</td>-->
<!--          </tr>-->
<!--        </table>-->
        <br>

        <br>
        <el-form-item label="Remarks" prop="comment" v-if="invoice_details.session_started_at">
          <el-input v-model="session.session_remarks" type="textarea"/>
        </el-form-item>
        <div class="text-center">
          <div v-if="invoice_details.session_started_at">
            <el-button type="danger" @click="startSession"> STOP</el-button>
          </div>
          <div v-else>
            <el-button type="success" @click="startSession">START</el-button>
          </div>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="generateInvoiceDialog = false">Cancel</el-button>
        <!--        <el-button type="primary" @click="submitInvoice('invoice')">-->
        <!--          Confirm-->
        <!--        </el-button>-->
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import UserCard from '@/components/core/UserCard';
import axios from 'axios';

export default {
  name: 'SessionStartStopModal',
  components: {
    UserCard,
  },
  props: {
    invoice_details: {},
  },
  data() {
    return {
      generateInvoiceDialog: false,
      session: {
        start: '',
        end: '',
        session_remarks: '',
      },
      total: 0,
      session_amount: 1500,
    };
  },
  created() {
    this.generateInvoiceDialog = true;
  },
  computed: {
    computedInvoiceDetails: {
      get() {
        return this.invoice_details;
      },
      set(newValue) {
        this.$emit('listUpdate', newValue);
      },
    },
    subtotal() {
      return this.invoice_details.therapies.reduce((sum, item) => sum + this.session_amount, 0);
    },
    total() {
      return this.getDiscount(this.subtotal, this.invoice.discount);
    },
  },
  methods: {
    startSession() {
      const form = new FormData();
      if (this.invoice_details.session_started_at) {
        form.append('action_type', 'end');
        form.append('session_remarks', this.session.session_remarks);
      } else {
        form.append('action_type', 'start');
      }
      axios.post(`/api/v2/appointment/${this.invoice_details.id}/session-update?_method=PUT`, form)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$emit('listUpdate', res.data.data);
            if (res.data.data.session_completed_at) {
              this.$notify({
                title: 'Success',
                message: 'Successfully End',
                type: 'success',
                duration: 2000,
              });
              this.generateInvoiceDialog = false;
            } else {
              this.$notify({
                title: 'Success',
                message: 'Successfully Start',
                type: 'success',
                duration: 2000,
              });

              this.generateInvoiceDialog = true;
            }
            this.invoice_details;
            this.session = {};
            // this.invoice_details = {};
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
    getDiscount(total, discountPercent) {
      const net_total = total * (discountPercent / 100);
      this.total = total - net_total;
      return net_total;
    },
  },
  watch: {
    generateInvoiceDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-right {
  text-align: right;
}
</style>
