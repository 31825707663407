<template>
  <el-dialog v-model="dialogInvoice" class="appointment-status-modal" size="sm" width="500px">
    <div class="dashed" id="printMe">
      <div class="invoice-header" :style="{ 'background-image': 'url('+require('@/assets/bg.jpg')+')' }">
        <div>
          <div id="parallelogram">
            <h2>INVOICE</h2>
          </div>
        </div>
        <div class="logo-area">
          <img :src="require('@/assets/logo.png')" alt="Orko health Ltd" class="invoice-logo">
          <p class="mt-0">Connect.Care</p>

          <div class="d-flex align-items-center">
            <div class="pointer">
              <div class="text-content">
                <p class="mb-0">Invoice To</p>
              </div>
            </div>
            <div class="mx-10">

              <UserCard :name="invoice_details.patient_name"
                        :phone_number="invoice_details.patient_phone">
              </UserCard>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div>
          <h2 class="mb-5">Doctor Information</h2>
          <p class="mb-0">{{ invoice_details.doctor_name }}</p>
          <p class="mb-0 mt-5">{{ invoice_details.doctor_phone_number }}</p>
          <p class="mb-0 mt-5">{{ invoice_details.institute_name }}</p>
        </div>
        <div>
          <p class="mt-0 mb-0 bg-gray"><span
              class="bg-blue">Invoice No</span><span>orko-0000{{ invoice_details.id }}</span></p>
          <p class="mb-0 mt-3 bg-gray"><span class="bg-blue">Date</span><span>{{ invoice_details.schedule_date }}</span>
          </p>
        </div>
      </div>

      <div class="text-right">
        <!--        <p><b>Schedule Date :</b> {{ invoice_details.schedule_date }} </p>-->
        <p><b>Start Time : </b>{{ invoice_details.schedule_started_at }} </p>
        <p><b>End Time : </b>{{ invoice_details.schedule_end_at }} </p>
      </div>
      <div class="text-right">
        <h4>Payable Amount : {{ invoice_details.payable_amount }}</h4>
        <el-tag class="ml-2" type="success" v-if="invoice_details.is_paid == 1"><h3>PAID</h3></el-tag>
        <el-tag class="ml-2" type="warning" v-else-if="invoice_details.is_paid == 2"><h3>PARTIAL PAID</h3></el-tag>
        <el-tag class="ml-2" type="danger" v-else>UNPAID</el-tag>
      </div>
      <p class="text-center mb-0">Thank For Your Business</p>
    </div>
  </el-dialog>
</template>

<script>
import UserCard from '@/components/core/UserCard';
import '@/assets/invoice.css';

export default {
  name: 'InvoiceModal',
  props: {
    invoice_details: {},
  },
  data() {
    return {
      dialogInvoice: false,
    };
  },
  components: {
    UserCard,
  },
  created() {
    this.dialogInvoice = true;
  },
  methods: {
    async print() {
      // Pass the element id here
      this.printing = false;
      await this.$htmlToPaper('printMe');
    },
  },
  watch: {
    dialogInvoice(oldValue, newValue) {
      if (oldValue == false) {
        this.$emit('modalClose', oldValue);
      }
    },
  },
};
</script>

<style scoped>

</style>
