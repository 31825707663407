<template>
  <div>
    <el-dialog
        v-model="examinationDetailsDialog"
        title="Examination Details"
        class="appointment-status-modal"
        size="lg">
      <div>

        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="Vital Sings" name="vital">
            <p v-for="(exam, index) in examinations_details.vital" :key="index">{{ exam.title }}: <b>{{ exam.term }}</b>
            </p>
          </el-tab-pane>
          <el-tab-pane label="General Examination" name="general" v-if="examinations_details.general">
            <p v-for="(exam, i) in examinations_details.general" :key="i">{{ exam.title }}: <b>{{
                exam.term
              }}</b>
            </p>
          </el-tab-pane>
          <el-tab-pane label="Systemic Examination" name="systemic" v-if="examinations_details.systemic">
            <p v-for="(exam, index) in examinations_details.systemic" :key="index">{{ exam.title }}: <b>{{
                exam.term
              }}</b>
            </p>
          </el-tab-pane>
          <el-tab-pane label="Physical Examination" name="physical" v-if="examinations_details.physical">
            <p v-for="(exam, index) in examinations_details.physical" :key="index">{{ exam.title }}: <b>{{
                exam.term
              }}</b>
            </p>
          </el-tab-pane>
          <el-tab-pane label="Pirani Score" name="pirani" v-if="examinations_details.pirani">
            <p v-for="(exam, index) in examinations_details.pirani" :key="index">{{ exam.title }}:
              <b>{{ exam.sides }}-{{
                  exam.term
                }}</b>
            </p>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="examinationDetailsDialog = false">Cancel</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExaminationDetailsModal',
  props: {
    examinations_details: {},
  },
  data() {
    return {
      activeName: 'vital',
      examinationDetailsDialog: false,
    };
  },
  created() {
    this.examinationDetailsDialog = true;
  },
  watch: {
    examinationDetailsDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>

</style>
