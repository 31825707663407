<template>
  <div>
    <el-dialog
        v-model="advice_details_dialog"
        title="Advice Details"
        class="appointment-status-modal"
        size="lg">
      <ol type="1">
        <li  v-for="(advice,index) in advices" :key="index">
          {{ advice.advice }}
        </li>
      </ol>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="advice_details_dialog = false">Cancel</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AdviceDetails',
  props: {
    appointment_details: {},
  },
  data() {
    return {
      advice_details_dialog: false,
      advices: [],
    };
  },
  created() {
    this.advice_details_dialog = true;
    if (this.advice_details_dialog) {
      this.getAdvice();
    }
  },
  methods: {
    getAdvice() {
      axios.get(`/api/v2/appointment/${this.appointment_details.id}/medical-advices`)
        .then((res) => {
          this.advices = res.data.data;
        });
    },
  },
  watch: {
    advice_details_dialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>

</style>
