<template>
    <div>
        <Patients :is_message="true"/>
    </div>
</template>
<script>
import Patients from './Patients.vue'
export default{
    name: 'MyPatient',
    components:{
        Patients,
    },
}
</script>