<template>
  <div>
    <el-card>
      <div class="text-right">
        <router-link :to="`/cancer-patient/update/${$route.params.id}`">
          <el-button type="primary" circle>
            <el-icon>
              <font-awesome-icon icon="fa fa-edit"/>
            </el-icon>
          </el-button>
        </router-link>
      </div>
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="Prescription" name="prescription">
          <el-button type="primary" @click="uploadDocuments('prescription')">Upload Prescription</el-button>
          <div v-if="prescriptions.length" class="demo-image">
            <el-row :gutter="30">
              <el-col :sm="12" :md="8" :lg="8" v-for="prescription in prescriptions" :key="prescription.id"
                      class="block">
                <el-image style="width: 300px; height:300px" :src="assetUrl(prescription.url)" :fit="fit"
                          @click="() => showImg(prescription,prescription.id)"/>
                <br>
                <a target="_blank" :href="assetUrl(prescription.url)" download>Download</a>
              </el-col>
            </el-row>
            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>

          <el-empty v-else :image-size="200"/>

        </el-tab-pane>
        <el-tab-pane label="Reports" name="report">
          <el-button type="primary" @click="uploadDocuments('report')">Upload Reports</el-button>
          <div v-if="reports.length" class="demo-image">
            <el-row :gutter="30">
              <el-col :sm="12" :md="8" :lg="8" v-for="(report, index) in reports" :key="index" class="block"
                      @click="() => showImg(report,index)">
                <el-image style="width: 300px; height:300px" :src="assetUrl(report.url)" :fit="fit"/>
                <br>
                <a target="_blank" :href="assetUrl(report.url)" download>Download</a>
              </el-col>

            </el-row>
            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>
          <el-empty v-else :image-size="200"/>
        </el-tab-pane>
        <el-tab-pane label="Image" name="image">
          <el-button type="primary" @click="uploadDocuments('image')">Upload Image</el-button>
          <div v-if="images.length" class="demo-image">
            <el-row :gutter="30">
              <el-col :sm="12" :md="8" :lg="8" v-for="(image, index) in images" :key="index" class="block"
                      @click="() => showImg(image,index)">
                <el-image style="width: 300px; height:300px" :src="assetUrl(image.url)" :fit="fit"/>
                <br>
                <a target="_blank" :href="assetUrl(image.url)" download>Download</a>
              </el-col>
            </el-row>

            <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                @hide="onHide"
            ></vue-easy-lightbox>
          </div>
          <el-empty v-else :image-size="200"/>
        </el-tab-pane>

        <el-tab-pane label="Pre Assessment" name="pre_assesment">
          <table>
            <tr v-for="(assesment,index) in pre_assessment" :key="index">
              <td>{{ assesment.question ? assesment.question.text : '' }}</td>
            </tr>
          </table>
        </el-tab-pane>
        <el-tab-pane label="Pre Screening" name="pre_screening">
          <div class="text-center">
            <img :src="require('@/assets/logo.png')" alt="Orko" class="logo" id="sidebar-logo">
            <p>CONNECT.CARE</p>
          </div>
          <!--      <h2 class="text-center"></h2>-->

          <h3 class="mb-0 table-heading"> Patient Basic Information </h3>
          <table class="patient_basic_information">
            <tr>
              <td width="20%">NAME OF PATIENT</td>
              <td colspan="3">{{ patient.fullname }}</td>
            </tr>
            <tr>
              <td width="20%"> FATHER'S NAME</td>
              <td colspan="3">{{ patient.father_name }}</td>
            </tr>
            <tr>
              <td width="20%">MOTHER'S NAME</td>
              <td colspan="3">{{ patient.mother_name }}</td>
            </tr>
            <tr>
              <td width="20%">GUARDIAN NAME</td>
              <td colspan="3">{{ pre_screening ? pre_screening.guardian_name : '' }}</td>
            </tr>
            <tr>
              <td width="20%">EDUCATION</td>
              <td colspan="3">{{ pre_screening.education }}</td>
            </tr>
            <tr>
              <td width="18%">AGE</td>
              <td>{{ patient.age }}</td>
              <td width="18%">SEX</td>
              <td>{{ patient.gender }}</td>
            </tr>
            <tr>
              <td>MARTIAL STATUS</td>
              <td>{{ pre_screening.marital_status }}</td>

              <td>RELIGION</td>
              <td>{{ pre_screening.religion }}</td>
            </tr>
            <tr>
              <td>FAMILY INCOME(in taka)</td>
              <td colspan="3">{{ pre_screening.family_income }}</td>
            </tr>
          </table>
          <br>

          <h3 class="mb-0 table-heading">Health History</h3>
          <table class="patient_basic_information">
            <tr>
              <td><span class="mx-10">{{ past_health_condition.text }}</span></td>
            </tr>
          </table>

          <h3 class="mb-0 table-heading">Smoking History</h3>
          <table class="patient_basic_information">
            <tr>
              <td width="40%">CURRENT TOBACCO SMOKING HABIT</td>
              <td>
                {{ smoking_habit.duration_of_smoking ? smoking_habit.duration_of_smoking + ' Year' : 'No' }}
              </td>
            </tr>
            <tr>
              <td>EVER TOBACCO SMOKING HABIT</td>
              <td>{{ smoking_habit.ever_smoking_habit != 0 ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>CURRENT TOBACCO CHEWING HABIT</td>
              <td>{{ smoking_habit.tobacco_chewing_habit == 1 ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>PARITY(in case of female)</td>
              <td>{{ smoking_habit.parity }}</td>
            </tr>
            <tr>
              <td>SOURCE OF REFERRAL</td>
              <td>{{ pre_screening.referred_by }}</td>
            </tr>
          </table>
        </el-tab-pane>
        <el-tab-pane label="Appointments" name="appointments">
          <PatientAppointment/>
        </el-tab-pane>

        <PatientDocumentUploadModal :document_type="document_type" v-if="document_upload_dialog"
                                    @closeModal="closeModal"/>
      </el-tabs>

    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import VueEasyLightbox from 'vue-easy-lightbox';
import PatientAppointment from '@/views/patient/PatientAppointments';
import PatientDocumentUploadModal from '../../components/modal/PatientDocumentUploadModal'; // work

export default {
  name: 'CancerPatientDetails',
  title: 'Cancer Screening Patient',
  components: {
    VueEasyLightbox,
    PatientDocumentUploadModal,
    PatientAppointment,
  },
  data() {
    return {
      patient: {},
      pre_screening: {},
      past_health_condition: {},
      smoking_habit: {},
      activeName: 'prescription',
      prescriptions: [],
      reports: [],
      images: [],
      fit: 'cover',
      imgsRef: [],
      indexRef: 0,
      visibleRef: false,
      document_upload_dialog: false,
      document_type: '',
      pre_assessment: [],
    };
  },
  created() {
    this.getPatient();
    this.getReports();
  },
  methods: {
    getPatient() {
      axios.get(`/api/v1/cancer/patient/details?patient_id=${this.$route.params.id}`)
        .then((res) => {
          this.patient = res.data.data.patient;
          this.pre_assessment = res.data.data.pre_assessment;
          this.pre_screening = res.data.data.pre_screening;
          this.past_health_condition = res.data.data.past_health_condition;
          this.smoking_habit = res.data.data.smoking_habit;
          this.pre_assessment = res.data.data.pre_assessment;
        });
    },
    getReports() {
      this.loading = true;
      axios.get(`/api/v1/patient/document/list?patient_id=${this.$route.params.id}`)
        .then((response) => {
          response.data.data.map((item) => {
            if (item.type == 'prescription') {
              this.prescriptions.push(item);
            } else if (item.type == 'image') {
              this.images.push(item);
            } else {
              this.reports.push(item);
            }
          });
          this.loading = false;
        });
    },
    assetUrl(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    showImg(imgObject, index) {
      this.imgsRef = this.assetUrl(imgObject.url);
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    uploadDocuments(value) {
      this.document_upload_dialog = true;
      this.document_type = value;
    },
    closeModal(value) {
      this.document_upload_dialog = false;
      if (value) {
        value.forEach((item) => {
          if (item.type == 'prescription') {
            this.prescriptions.push(item);
          } else if (item.type == 'image') {
            this.images.push(item);
          } else {
            this.reports.push(item);
          }
        });
      }

      // this.getReports();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px 0px;
  padding: 0;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;

}

h3 {
  background: #000000;
  color: #ffffff;
  padding: 10px 2px;
}

.logo {
  height: 80px;
}

.patient_basic_information tr td {
  padding-left: 15px;
}

.table-heading {
  padding-left: 15px;
}
</style>
