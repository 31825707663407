<template>

  <el-card shadow="never" class="border-0">
    <el-form label-position="left">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane class="basic_info" label="Basic Information" name="patient_info">

          <el-form-item label="PATIENT NAME">
            <el-input v-model="patient.fullname" disabled/>
          </el-form-item>

          <el-form-item label="SEX">
            <el-radio-group v-model="patient.gender" disabled>
              <el-radio label="male">MALE</el-radio>
              <el-radio label="female">FEMALE</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="FATHER'S NAME">
            <el-input v-model="patient.father_name"/>
          </el-form-item>

          <el-form-item label="MOTHER'S NAME">
            <el-input v-model="patient.mother_name"/>
          </el-form-item>

          <el-form-item label="GUARDIAN NAME">
            <el-input v-model="pre_screening.guardian_name"/>
          </el-form-item>

          <el-form-item label="ADDRESS">
            <el-input v-model="patient.location" class="ml-40"/>
          </el-form-item>

          <el-form-item label="RELIGION">
            <el-radio-group v-model="pre_screening.religion">
              <el-radio label="Islam" size="large">Islam</el-radio>
              <el-radio label="Hinduism" size="large">Hinduism</el-radio>
              <el-radio label="Christianity" size="large">Christianity</el-radio>
              <el-radio label="Buddhism" size="large">Buddhism</el-radio>
              <el-radio label="Other" size="large">Other</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="Marital status">
            <el-radio-group v-model="pre_screening.marital_status">
              <el-radio label="Unmarried" size="large">Unmarried</el-radio>
              <el-radio label="Married" size="large">Married</el-radio>
              <el-radio label="Widowed" size="large">Widowed</el-radio>
              <el-radio label="Divorced" size="large">Divorced</el-radio>
              <el-radio label="Other" size="large">Other</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="EDUCATION">
            <el-radio-group v-model="pre_screening.education">
              <el-radio label="Not applicable" size="large">Not applicable</el-radio>
              <el-radio label="Illiterate" size="large">Illiterate</el-radio>
              <el-radio label="Primary" size="large">Primary</el-radio>
              <el-radio label="Secondary" size="large">Secondary</el-radio>
              <el-radio label="Higher Secondary" size="large">Higher Secondary</el-radio>
              <el-radio label="Graduate and above" size="large">Graduate and above</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="OCCUPATION">
            <el-select v-model="patient.nature_of_service" class="w-100">
              <el-option
                  v-for="item in nature_of_services"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="FAMILY INCOME(in taka)">
            <el-input v-model="pre_screening.family_income" type="number" oninput="this.value = Math.abs(this.value)"  min="0"/>
          </el-form-item>
          <el-form-item label="SOURCE OF REFERRAL">
            <el-radio-group v-model="pre_screening.referred_by">
              <el-radio label="Self" size="large">Self</el-radio>
              <el-radio label="GP" size="large">GP</el-radio>
              <el-radio label="Specialist/Oncologist" size="large">Specialist/Oncologist</el-radio>
              <el-radio label="Govt. Hosp." size="large">Govt. Hosp.</el-radio>
              <el-radio label="Private/clinic" size="large">Private/clinic</el-radio>
              <el-radio label="Cancer Center/home other" size="large">Cancer Center/home other</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-tab-pane>
        <el-tab-pane label="Health History" name="pregnancy_before">

          <el-form-item>
            <el-radio-group v-model="pre_screening.pre_screening_health_status_id" class="ml-4"
                            style="display: block !important;">
              <el-radio :label="question.id" size="large" v-for="(question, index) in health_questions" :key="index"
                        style="display: block !important;">
                {{ question.text }}
              </el-radio>
            </el-radio-group>
          </el-form-item>

        </el-tab-pane>
        <el-tab-pane class="smoking_history" label="Smoking History" name="pregnancy_after">
          <el-form-item label="CURRENT TOBACCO SMOKING HABIT">
            <!--            <div class="d-flex">-->
            <el-radio-group v-model="duration_of_smoking">
              <el-radio :label="1" size="large">Yes</el-radio>
              <el-radio :label="0" size="large">No</el-radio>
            </el-radio-group>

            <!--            </div>-->
          </el-form-item>
          <el-form-item label="" v-if="duration_of_smoking==1">
            <div class="hide-input-filed">
              <el-input v-model="smoking_habit.duration_of_smoking"
                        oninput="this.value = Math.abs(this.value)" :max="99" :min="0"
                        type="number">

                <template #append>Year</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="EVER TOBACCO SMOKING HABIT">
            <el-radio-group v-model="smoking_habit.ever_smoking_habit">
              <el-radio :label="1" size="large">Yes</el-radio>
              <el-radio :label="0" size="large">No</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="CURRENT TOBACCO CHEWING HABIT">
            <el-radio-group v-model="smoking_habit.tobacco_chewing_habit">
              <el-radio :label="1" size="large">Yes</el-radio>
              <el-radio :label="0" size="large">No</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="PARITY(in case of female)">
            <el-input v-model="smoking_habit.parity"/>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <div class="text-center">
        <el-button type="primary" class="floting-button" @click="updateCFPatient">Update</el-button>
      </div>
    </el-form>
  </el-card>

</template>

<script>
import axios from 'axios';

export default {
  name: 'CancerScreeningPatinetRegistration',
  title: 'Patient Registration',
  data() {
    return {
      activeName: 'patient_info',
      loading: false,

      duration_of_smoking: '',
      health_questions: [],
      form: {},
      nature_of_services: [],
      patient: {
        fullname: '',
        gender: '',
        father_name: '',
        mother_name: '',
        location: '',
        nature_of_service: '',
      },
      pre_assessment: {},
      pre_screening: {
        religion: '',
        marital_status: '',
        education: '',
        guardian_name: '',
        family_income: '',
        referred_by: '',
        pre_screening_health_status_id: '',
      },
      past_health_condition: {},
      smoking_habit: {
        duration_of_smoking: '',
        ever_smoking_habit: '',
        tobacco_chewing_habit: '',
        parity: '',
      },
    };
  },
  created() {
    this.getPatientDetails();
    this.getPreQuestion();
    this.medicalServiceList();
  },
  methods: {
    getPatientDetails() {
      this.loading = true;
      axios.get(`/api/v1/cancer/patient/details?patient_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.patient) {
              this.patient.fullname = res.data.data.patient.fullname;
              this.patient.gender = res.data.data.patient.gender;
              this.patient.father_name = res.data.data.patient.father_name;
              this.patient.mother_name = res.data.data.patient.mother_name;
              this.patient.location = res.data.data.patient.location;
              if (res.data.data.patient.nature_of_service_id) {
                this.patient.nature_of_service = res.data.data.patient.nature_of_service_id;
              }
            }
            this.pre_assessment = res.data.data.pre_assessment;
            this.pre_screening = res.data.data.pre_screening;
            this.past_health_condition = res.data.data.past_health_condition;
            if (res.data.data.smoking_habit.duration_of_smoking != 0) {
              this.duration_of_smoking = 1;
            }
            this.smoking_habit = res.data.data.smoking_habit;
          }
          this.loading = false;
        });
    },
    getPreQuestion() {
      axios.get('/api/v1/cancer/patient/health/question')
        .then((res) => {
          this.health_questions = res.data.data;
        });
    },
    updateCFPatient() {
      // let final_data = this.pre_screening.concat(this.smoking_habit, this.patient, this.examinations_physical,);
      const final_data = { ...this.pre_screening, ...this.smoking_habit, ...this.patient };
      const url = `api/v1/cancer/patient/pre-screening?patient_id=${this.$route.params.id}`;

      axios.post(url, final_data)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.getPatientDetails();
            this.$notify({
              title: 'Success',
              message: 'Succesfully Updated',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
    medicalServiceList() {
      axios.get('/api/v1/medical/services/list')
        .then((res) => {
          this.nature_of_services = res.data.data;
          if (this.patient.nature_of_service_id) {
            this.patient.nature_of_service = this.patient.nature_of_service_id;
          } else {
            const nature_of_service_obj = this.nature_of_services.find((item) => item.title === 'Other');
            this.patient.nature_of_service = nature_of_service_obj.id;
          }
        });
    },
  },
};
</script>
<style scoped>
.floting-button {
  position: fixed;
  bottom: 65px;
  right: 25px;
  margin: 15px;
  /*height: 70px;*/
  /*width: 70px;*/
  /*border-radius: 100px;*/
  cursor: pointer;
  z-index: 9999;
}
</style>
<style>
.w-100 {
  width: 100% !important;
}

.basic_info .el-form-item label {
  width: 16%;
}

.el-radio-group {
  width: 100%;
}

.smoking_history .el-form-item label {
  width: 30%;
}
.hide-input-filed {
  width: 200px;
  display: flex;
  /* justify-content: end; */
  position: absolute;
  top: -54px;
  right: 14%;
}
</style>
