<template>
    <div>
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="doctors" label="Doctors" width="300">
          <template #default="scope">
            <UserCard :name="scope.row.doctors ? scope.row.doctors.fullname : '-'"
                      :phone_number="scope.row.doctors ? scope.row.doctors.phone_number : ''"
                      :image="scope.row.doctors ? scope.row.doctors.image : ''"></UserCard>
          </template>
        </el-table-column>
        <el-table-column prop="is_paid" label="Payment Status" width="140">
          <template #default="scope">
            <div>
              <el-tag class="ml-2" v-if="scope.row.is_paid == 'paid'" type="success">Paid</el-tag>
              <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
            </div>
          </template>
        </el-table-column>
  
        <el-table-column prop="prescription_identifier" label="Prescription identifier">
          <template #default="scope">
            {{ scope.row.prescription_identifier }}
          </template>
        </el-table-column>
        <el-table-column prop="follow_up_date" label="Follow up date"/>
        <el-table-column prop="created_date" label="Create Date"/>
        <el-table-column prop="actions" label="Actions">
            <template #default="scope">
            <el-button type="warning" @click="$router.push('/prescription/preview/'+scope.row.prescription_identifier)" circle><el-icon><Printer /></el-icon></el-button>
            <el-button type="info" @click="downloadPrescription(scope.row.prescription_identifier)" circle><el-icon><Download /></el-icon></el-button>
            </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-model:current-page="currentPage"
          :page-size="per_page"
          layout="total, prev, pager, next"
          :total="total_count"
          @current-change="getItems"
      />
    </div> 
  </template>
  <script>
  import axios from 'axios';
  import UserCard from '../../components/core/UserCard';
  
  export default {
    name: 'PatientMedicalRecord',
    components: {
      UserCard,
    },
    data() {
      return {
        items: [],
        per_page: 20,
        total_count: 0,
        currentPage: 1,
      };
    },
    created() {
      this.getItems();
    },
    methods: {
      getItems(val) {
        if (val) {
          const offset = val - 1;
          var correctValue = offset * this.per_page;
        }
        axios.get(`/api/v1/patient/${this.$route.params.id}/prescriptions?limit=${this.per_page}&offset=${correctValue || 0}`)
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
        });
      },
      prescriptionUrl(url) {
        if (url) {
          return `${process.env.VUE_APP_API_URL}/${url}`;
        }
        return '';
      },
    downloadPrescription(identifier) {
        axios.get(`/api/v2/prescription/${identifier}/download`,
        { responseType: 'blob' }
        )
        .then((response) => {
            const filename = response.headers['content-disposition']
                .split('filename=')[1]
                .split('.')[0];
            const extension = response.headers['content-disposition']
                .split('.')[1]
                .split(';')[0];
            const url = URL.createObjectURL(
                new Blob([response.data], {
                type: 'application/vnd.ms-excel',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            });
        },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  