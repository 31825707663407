<template>
  <div>
    <el-dialog v-model="dialogTableVisible" title="Upload Documents">
      <el-upload
          action="#"
          list-type="picture-card"
          :file-list="file_lists"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="handleRemove"
      >
        <el-icon>
          <Plus/>
        </el-icon>

        <template #file="{ file }">
          <div>
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt=""/>
            <span class="el-upload-list__item-actions">
          <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
          >
            <el-icon><zoom-in/></el-icon>
          </span>
<!--          <span-->
<!--              v-if="!disabled"-->
<!--              class="el-upload-list__item-delete"-->
<!--              @click="handleRemove(file)"-->
<!--          >-->
<!--            <el-icon><Delete/></el-icon>-->
<!--          </span>-->
        </span>
          </div>
        </template>
      </el-upload>

      <el-dialog v-model="dialogVisible">
        <img w-full :src="dialogImageUrl" alt="Preview Image"/>
      </el-dialog>
      <div class="text-center mt-10">
        <el-button @click="upload('report')">Upload</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PatientDocumentUploadModal',
  props: {
    document_type: {},
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      records: [],
      file_lists: [],
      tempDocument: [],
    };
  },
  created() {
    this.dialogTableVisible = true;
  },
  methods: {
    handleRemove(file) {
      // console.log('file', uploadFile, 'files',uploadFiles);
      this.tempDocument.splice(file, 1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    fileChange(file) {
      this.tempDocument.push(file.raw);
    },
    upload() {
      let form = new FormData();
      this.tempDocument.forEach((item, index) => {
        form.append(`document[${index}][file]`, item);
        form.append(`document[${index}][type]`, this.document_type);
      });

      axios.post(`/api/v1/patient/document/store?document_for=${this.$route.params.id}`, form)
        .then((response) => {
          if (response.data.status_code == 200) {
            this.$emit('closeModal', response.data.data);
            form = {};
            this.$notify({
              title: 'Success',
              message: 'Succesfully Uploaded',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: response.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
  },
  watch: {
    dialogTableVisible(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
