<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row class="mb-10">
      <el-col :sm="24" :md="12" :lg="12" v-if="authUser.user_type == 'executive' || authUser.user_type == 'crm-manager'">
        <el-select @change="getTodayAppointment()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large" clearable>
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" align="center">
      <el-col :sm="2" :md="2" :lg="2">
        <el-checkbox v-model="isToday" label="Today" size="large" @change="getTodayAppointment"/>
      </el-col>
      <el-col :sm="8" :md="6" :lg="6">
        <div class="w-100">
          <el-select
              v-model="form.patient_id"
              filterable
              remote
              reserve-keyword
              placeholder="Please enter phone number"
              :remote-method="searchGlobalPatient"
              :loading="loading"
              class="w-100"
              clearable
              size="large"
          >
            <el-option
                v-for="item in patients"
                :key="item.id"
                :label="item.fullname"
                :value="item.id"
            />
          </el-select>

        </div>
      </el-col>
      <el-col :sm="8" :md="5" :lg="5">
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Start date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
            :disabled="isToday ? isToday : false"
        />
      </el-col>
      <el-col :sm="8" :md="5" :lg="5">
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="End date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
            :disabled="isToday ? isToday : false"
        />
      </el-col>
      <el-col :sm="4" :md="4" :lg="4">
        <el-button type="primary" size="large" class="mx-5" @click="getTodayAppointment">Filter</el-button>
        <el-button
          type="success"
          size="large"
          v-if="checkUsrPermissions('Export Todays Appointment List for Occ')"
          @click="exportToExcel"
          >Export
        </el-button>
      </el-col>
      <el-col :sm="1" :md="1" :lg="1">
        <div class="text-right">
          <el-button type="warning" size="large" @click="getTodayAppointment" circle>
            <font-awesome-icon icon="fa-solid fa-rotate-right"/>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <br>
    <el-table :data="tableData" border>
      <el-table-column prop="doctor_name" label="Doctor" width="200">
        <template #default="scope">
          <UserCard :name="scope.row.doctor_name"
                    :phone_number="scope.row.doctor_phone_number"
                    :image="scope.row.doctor_image"></UserCard>
          <el-tag class="ml-2 w-100" v-if="scope.row.institute_type == 'Therapy'">
            {{ scope.row.institute_type }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="patient_name" label="Patient" width="200">
        <template #default="scope">
          <UserCard :name="scope.row.patient_name"
                    :phone_number="scope.row.patient_phone"
                    :image="scope.row.patient_image"></UserCard>
        </template>
      </el-table-column>

      <el-table-column prop="appointment_status" label="Status" width="210">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" type="success" v-if="scope.row.appointment_status=='booked'">
              {{ scope.row.appointment_status }}
            </el-tag>
            <el-tag class="ml-2" type="danger" v-else-if="scope.row.appointment_status=='canceled'">
              {{ scope.row.appointment_status }}
            </el-tag>
            <el-tag class="ml-2"  v-else-if="scope.row.appointment_status=='served'">
              {{ scope.row.appointment_status }}
            </el-tag>
            <el-tag class="ml-2" type="info" v-else>{{ scope.row.appointment_status }}</el-tag>

            <br/>
            Booked By: <b>{{ scope.row.booked_by?scope.row.booked_by.fullname:'N/A' }}</b>

          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_followup" label="Followup" width="100">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" type="success" v-if="scope.row.is_followup == 1">Yes</el-tag>
            <el-tag class="ml-2" type="danger" v-else>No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="schedule_date" label="Schedule date/time" width="180">
        <template #default="scope">
          <div class="text-center">
            <p>{{ scope.row.schedule_date }}</p>
            <el-tag class="ml-2" type="info">{{ scope.row.schedule_started_at }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_paid" label="Payment" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.is_paid == 1" type="success">Paid</el-tag>
            <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Examinations Added?" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.has_examinations == 1" type="success">Yes</el-tag>
            <el-tag class="ml-2" v-else type="danger">No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="consultancy" label="Consultancy" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.consultancy == 1" type="success">Yes</el-tag>
            <el-tag class="ml-2" v-else type="danger">No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="invoice_uid" label="Invoice No" width="150">
          <template #default="scope">
            <router-link v-if="scope.row.invoice_uid" :to="`/invoice/${scope.row.invoice_uid}`" target="_blank">{{ scope.row.invoice_uid?scope.row.invoice_uid.replace('INV-',''):'N/A' }}</router-link>
            <span v-else>N/A</span>
          </template>
        </el-table-column>
      <el-table-column prop="session_completed_at" label="Physio Assistant" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.session_completed_at" type="success">Session Completed</el-tag>
            <el-tag class="ml-2" v-else-if="scope.row.session_started_at" type="warning">Session Started</el-tag>
            <el-tag class="ml-2" v-else type="danger">Not start yet</el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_count"
        @current-change="getTodayAppointment"
    />
  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '@/components/core/UserCard';
import { CenterMixins } from '@/mixins/CenterMixins';
import { mapGetters } from 'vuex';

export default {
  name: 'TodayAppointment',
  components: {
    UserCard,
  },
  mixins: [CenterMixins],
  title: 'Appointment history',
  data() {
    return {
      loading: false,
      tableData: [],
      total_count: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      isToday: true,
      form: {
        patient_id: '',
      },
      patients: [],
    };
  },
  created() {
    this.getTodayAppointment();
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  methods: {
    getTodayAppointment(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      axios.get(`/api/v2/appointment/list/occ-today?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.isToday ? '&has_schedule_today=yes' : ''}${this.form.patient_id ? `&patient_id=${this.form.patient_id}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}`)
        .then((res) => {
          this.tableData = res.data.data;
          this.total_count = res.data.total_count;
        });
    },
    exportToExcel() {
      axios
        .get(
          `/api/v2/appointment/list/occ-today/export?${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.isToday ? '&has_schedule_today=yes' : ''}${this.form.patient_id ? `&patient_id=${this.form.patient_id}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(".")[0];
          const extension = response.headers["content-disposition"]
            .split(".")[1]
            .split(";")[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },

    searchGlobalPatient(value) {
      axios.get(`/api/v1/patient/subordinate/list?term=${value}`)
        .then((response) => {
          this.patients = response.data.data;
        });
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.getTodayAppointment();
    },
  },
};
</script>

<style scoped>

</style>
