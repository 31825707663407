<template>
  <div>
    <el-table :data="appointments" border style="width: 100%" v-loading="loading">
      <el-table-column prop="doctor_id" label="Doctor">
        <template #default="scope">
          <UserCard :name="scope.row.doctor_name"
                    :phone_number="scope.row.doctor_phone_number"
                    :image="scope.row.doctor_image"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="schedule_date" label="Schedule date"/>
      <!--      <el-table-column prop="appointment_status" label="Appointment status"/>-->
      <el-table-column prop="appointment_status" label="Appointment status" width="180">
        <template #default="scope">
          <el-tag class="ml-2 " type="success" v-if="scope.row.appointment_status == 'booked'">
            {{ scope.row.appointment_status }}
          </el-tag>
          <el-tag class="ml-2 " type="info" v-else-if="scope.row.appointment_status == 'served'">{{ scope.row.appointment_status }}</el-tag>
          <el-tag class="ml-2 " type="danger" v-else>{{ scope.row.appointment_status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="appointment_type" label="Appointment type">
        <template #default="scope">
          <el-tag
              type="success"
              class="mx-1"
              effect="dark" v-if="scope.row.appointment_type == 'booked_appointment'">
            Consultancy
          </el-tag>
          <el-tag
              type="success"
              class="mx-1"
              effect="dark" v-else-if="scope.row.appointment_type == 'quick_care'">
            Consultancy
          </el-tag>
          <el-tag
              type="success"
              class="mx-1"
              effect="dark" v-else-if="scope.row.appointment_type == 'walkin_appointment'">
            Consultancy
          </el-tag>
          <el-tag
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'therapy_appointment'">
              Therapy Appointment
            </el-tag>
            <el-tag
                type="warning"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'clubfoot_appointment'">
              Clubfoot Appointment
            </el-tag>
            <el-tag
                type="warning"
                class="mx-1"
                effect="dark" v-else-if="scope.row.appointment_type == 'dental_appointment'">
              Dental Appointment
            </el-tag>
          <el-tag
              v-else
              type="primary"
              class="mx-1"
              effect="dark">
            Therapy
          </el-tag>

        </template>
      </el-table-column>
      <el-table-column prop="payable_amount" label="Payable amount"/>
      <el-table-column prop="is_paid" label="Payment Status" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.is_paid == 1" type="success">Paid</el-tag>
            <!--            <el-tag class="ml-2" v-else-if="scope.row.is_paid == 2" type="info">Partial paid (AP)</el-tag>-->
            <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
          </div>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        v-model:current-page="appointment_current_page"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_appointment"
        @current-change="getAppointment"
    />
  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '@/components/core/UserCard';

export default {
  name: 'PatientAppointments',
  components: {
    UserCard,
  },
  data() {
    return {
      loading: false,
      appointments: [],
      total_appointment: 0,
      appointment_current_page: 1,
      perPage: 20,
    };
  },
  created() {
    this.getAppointment();
  },
  methods: {
    getAppointment(value) {
      this.loading = true;
      if (Number(value)) {
        this.appointment_current_page = value;
        const offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/appointment/patients/${this.$route.params.id}/list?api_token=${localStorage.getItem('api_token')}&limit=20&offset=${correctValue || 0}`)
        .then((res) => {
          this.appointments = res.data.data;
          this.total_appointment = res.data.total_count;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
