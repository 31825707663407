<template>
  <div>
    <el-table :data="invoices" border style="width: 100%" v-loading="loading">
      <el-table-column prop="uid" label="Invoice No">
        <template #default="scope">
          <router-link :to="`/invoice/${scope.row.uid}`">{{ scope.row.uid }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="product_type" label="Product type"/>
      <el-table-column prop="subtotal" label="Subtotal"/>
      <el-table-column prop="total" label="Total"/>
      <el-table-column prop="discount_percentage" label="Discount percentage"/>
      <el-table-column prop="discount_reason" label="Discount reason"/>
      <el-table-column prop="invoice_date" label="Invoice date"/>
      <el-table-column prop="comment" label="Comment"/>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PatientInvoices',
  data() {
    return {
      perPage: 20,
      total_invoice: 0,
      invoices: [],
      loading: false,
      invoice_current_page: 1,
    };
  },
  created() {
    this.getInvoice();
  },
  methods: {
    getInvoice(value) {
      this.loading = true;
      if (Number(value)) {
        this.invoice_current_page = value;
        const offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`/api/v2/invoices?patient_id=${this.$route.params.id}&limit=20&offset=${correctValue || 0}`)
        .then((res) => {
          this.invoices = res.data.data;
          this.total_invoice = res.data.total_count;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
