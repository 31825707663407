<template>
  <el-dialog
      v-model="examinationDetailsDialog"
      title="Pirani Score"
      class="appointment-status-modal"
      size="lg">
    <div>

      <p v-for="(exam, index) in examinations_details.pirani" :key="index">{{ exam.title }}:
        <b>{{ exam.sides }}-{{
            exam.term
          }}</b>
      </p>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="examinationDetailsDialog = false">Cancel</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'PiranScoreViewModal',
  props: {
    examinations_details: {},
  },
  data() {
    return {
      examinationDetailsDialog: false,
    };
  },
  created() {
    this.examinationDetailsDialog = true;
  },
  watch: {
    examinationDetailsDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>

</style>
