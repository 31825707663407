<template>
<el-form
    label-position="top">
    <el-row :gutter="20">
        <el-col :span="24">
            <el-form-item label="Language" prop="language">
                <el-select v-model="form.language" class="w-100">
                    <el-option label="Bangla" value="bangla" />
                    <el-option label="English" value="english" />
                </el-select>
            </el-form-item>
      </el-col>
      
      <el-col :span="24">
            <el-form-item label="Message" prop="body">
                <el-input v-model="form.body" type="textarea" />
            </el-form-item>
      </el-col>
      
      <br/>
      <el-col :span="24" class="mt-5">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitForm('form')">
          Confirm
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
    name: 'MessageForm',
    props: {
    patient_id: {
      type: Number,
      required: false,
    },
    log_id: {
        type: Number,
        required: false,
    },
  },
  data() {
    return {
      form: {
        id: '',
        body: '',
        language: 'bangla',
        receiver_type: 'patient',
        message_type: 'single',
        receiver_ids: [],
        is_published: 0,
        user_id: '',
      },
      call_categories: [],
      call_status: [],
    };
  },
  created() {
    this.callCategory();
    this.callStatus();
  },
  computed: {

    ...mapGetters('auth', ['authUser', 'userInstitute']),

  },
  methods: {
    callCategory() {
      axios.get('/api/v1/agent/call/category/list?call_type=outbound')
        .then((response) => {
          this.call_categories = response.data.data;
        });
    },
    callStatus() {
      axios.get('/api/v1/agent/call/action/status/list')
        .then((response) => {
          this.call_status = response.data.data;
        });
    },
    closeModal() {
      this.$emit('dialogClose');
    },
    submitForm() {
      this.form.receiver_ids.push(this.patient_id);
      this.form.is_published = 1;
      let url = 'api/v2/agent/messages';
      axios.post(url, this.form)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$emit('dialogClose');
            if(this.log_id) {
                this.$emit('callApi');
            }
            this.form ={
                id: '',
                body: '',
                language: 'bangla',
                receiver_type: 'patient',
                message_type: 'single',
                receiver_ids: [],
                is_published: 1,
                user_id: '',
            },
            this.$notify({
              title: 'Success',
              message: 'Message send successfully',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
  },
}
</script>