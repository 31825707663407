<template>
  <el-dialog
      v-model="examinationDialog"
      title="Examinations"
      class="appointment-status-modal">

    <el-tabs v-model="examinationTab" class="demo-tabs" @tab-click="examinationTabClick">
      <el-tab-pane label="Vital Sings" name="vital">
        <el-row :gutter="20" v-for="(examination, index) in examinations_vital" :key="index">
          <el-col :span="10">
            <el-select class="m-2" placeholder="Select Vital Signs"
                       v-model="examination.type_id" clearable>
              <el-option
                  v-for="item in examinations_signs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="examination.term" placeholder="Enter value"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button @click="removeExamination(examinations_vital,index)" type="danger" icon="Delete" circle/>
          </el-col>
        </el-row>
        <el-button class="text-right" type="primary" @click="addMoreExaminationVital">Add more</el-button>
      </el-tab-pane>
      <el-tab-pane label="General Examination" name="general">
        <el-row :gutter="20" v-for="(examination, index) in examinations_general" :key="index">
          <el-col :span="10">
            <el-select class="m-2" placeholder="Select One"
                       v-model="examination.type_id" clearable>
              <el-option
                  v-for="item in examinations_signs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="examination.term" placeholder="Enter value"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button @click="removeExamination(examinations_general,index)" type="danger" icon="Delete" circle/>
          </el-col>
        </el-row>
        <el-button class="text-right" type="primary" @click="addMoreExaminationGeneral">Add</el-button>
      </el-tab-pane>
      <el-tab-pane label="Systemic Examination" name="systemic">
        <el-row :gutter="20" v-for="(examination, index) in examinations_systemic" :key="index">
          <el-col :span="10">
            <el-select class="m-2" placeholder="Select One"
                       v-model="examination.type_id" clearable>
              <el-option
                  v-for="item in examinations_signs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="examination.term" placeholder="Enter value"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button @click="removeExamination(examinations_systemic,index)" type="danger" icon="Delete" circle/>
          </el-col>
        </el-row>
        <el-button class="text-right" type="primary" @click="addMoreExaminationSystemic">Add</el-button>
      </el-tab-pane>
      <el-tab-pane label="Physical Examination" name="physical">
        <el-row :gutter="20" v-for="(examination, index) in examinations_physical" :key="index">
          <el-col :span="10">
            <el-select class="m-2" placeholder="Select One"
                       v-model="examination.type_id" clearable>
              <el-option
                  v-for="item in examinations_signs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="examination.term" placeholder="Enter value"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button @click="removeExamination(examinations_physical,index)" type="danger" icon="Delete" circle/>
          </el-col>
        </el-row>
        <el-button class="text-right" type="primary" @click="addMoreExaminationPhysical">Add</el-button>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="examinationDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submitExamination('form')">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ExaminationModal',
  props: {
    appointment_details: {},
  },
  data() {
    return {
      examinationDialog: false,
      examinationTab: 'vital',
      examinations_signs: [],
      formRef: null,
      examinations_vital: [
        {
          type_id: '',
          term: '',
        },
      ],
      examinations_general: [],
      examinations_systemic: [],
      examinations_physical: [],
      evaluation_date: this.todayDate(),
    };
  },
  created() {
    this.examinationDialog = true;
    if (this.examinationDialog) {
      this.getMedicalExamination('vital');
    }
  },

  methods: {
    examinationTabClick(tab, event) {
      this.getMedicalExamination(tab.props.name);
    },

    removeExamination(examination, index) {
      examination.splice(index, 1);
    },
    addMoreExaminationVital() {
      this.examinations_vital.push({
        type_id: '',
        term: '',
      });
    },
    addMoreExaminationGeneral() {
      this.examinations_general.push({
        type_id: '',
        term: '',
      });
    },
    addMoreExaminationSystemic() {
      this.examinations_systemic.push({
        type_id: '',
        term: '',
      });
    },
    addMoreExaminationPhysical() {
      this.examinations_physical.push({
        type_id: '',
        term: '',
      });
    },
    vitalSignValueCheck(item) {
      if (item.type_id && item.term) {
        return true;
      }
      return false;
    },
    getMedicalExamination(type) {
      axios.get(`/api/v1/medical-general-examination/list?type=${type}`)
        .then((res) => {
          this.examinations_signs = res.data.data;
        });
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
    submitExamination() {
      const checkIsTrueOrFalse = this.examinations_vital.some(this.vitalSignValueCheck);
      if (checkIsTrueOrFalse) {
        const final_examinations = this.examinations_vital.concat(this.examinations_general, this.examinations_systemic, this.examinations_physical);
        axios.post(`/api/v2/appointment/${this.appointment_details.id}/medical-examinations`, { examinations: final_examinations })
          .then((res) => {
            if (res.data.status_code == 200) {
              this.$notify({
                title: 'Success',
                message: 'Successfully saved',
                type: 'success',
                duration: 2000,
              });
              this.examinationDialog = false;
            } else {
              this.$notify({
                title: 'Failed',
                message: res.data.message,
                type: 'danger',
                duration: 2000,
              });
            }
          });
      } else {
        this.$notify({
          title: 'Failed',
          message: 'Please add vital sign',
          type: 'error',
          duration: 2000,
        });
      }
    },
  },
  watch: {
    examinationDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
        this.examinations_vital = [
          {
            type_id: '',
            term: '',
          },
        ];
        this.examinations_general = [];
        this.examinations_systemic = [];
        this.examinations_physical = [];
      }
    },
  },
};
</script>

<style scoped>

</style>
