<template>
  <div>
    <el-table :data="items" border style="width: 100%">
      <el-table-column prop="doctors" label="Doctors" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.doctors ? scope.row.doctors.fullname : '-'"
                    :phone_number="scope.row.doctors ? scope.row.doctors.phone_number : ''"
                    :image="scope.row.doctors ? scope.row.doctors.image : ''"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="patients" label="Patients" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.patients ? scope.row.patients.fullname: '-'"
                    :phone_number="scope.row.patients ? scope.row.patients.phone_number : ''"
                    :image="scope.row.patients ? scope.row.patients.image : ''"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="is_paid" label="Payment Status" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.is_paid == 'paid'" type="success">Paid</el-tag>
            <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="prescription_identifier" label="Prescription identifier">
        <template #default="scope">
          <a href="javascript:void(0)" @click="downloadPrescription(scope.row.prescription_identifier)">{{ scope.row.prescription_identifier }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="created_date" label="Create Date"/>
    </el-table>
    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_count"
        @current-change="getItems"
    />
  </div>

</template>

<script>
import axios from 'axios';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'PatientMedicalRecord',
  components: {
    UserCard,
  },
  data() {
    return {
      items: [],
      per_page: 20,
      total_count: 0,
      currentPage: 1,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      axios.get(`/api/v1/agent/prescription/by-patient?patient_id=${this.$route.params.id}&limit=${this.per_page}&offset=${correctValue || 0}`)
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
        });
    },
    downloadPrescription(identifier){
      
      axios.get(`/api/v2/prescription/${identifier}/download`,
       { responseType: 'blob' }
      )
      .then((response) => {
        
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
