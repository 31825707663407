<template>
  <div v-loading.fullscreen.lock="loading">

    <el-row justify="space-between" align="middle">
      <el-col :sm="20" :md="12" :lg="12">
        <el-form
            :model="search_form"
            :rules="search_rules"
            label-position="top"
            @submit.prevent="searchPatient('search_form')"
            ref="search_form"
        >
          <div class="d-flex align-items-center">
            <el-form-item label="Search Patient" prop="patient_q" class="w-100">
              <el-input
                  v-model="search_form.patient_q"
                  placeholder="Search Patient Phone Number"
                  class="input-with-select"
                  type="number"
                  min="0"
                  clearable
              >
              </el-input>
            </el-form-item>

            <el-button type="success" class="mt-5 mx-5" @click="searchPatient('search_form')" round>Search</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>

    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Phone Number</p>
        <el-input v-model="q" size="large" type="number"/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getPatients" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
      <el-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" class="mt-40">
        <el-button type="warning" size="large" @click="getPatients" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right"/>
        </el-button>
      </el-col>
    </el-row>
    <br>
    <el-table :data="filteredList" border style="width: 100%">
      <el-table-column prop="fullname" label="Name"/>
      <el-table-column prop="phone_number" label="phone">
        <template #default="scope">
          {{ scope.row.phone_number }}
        </template>
      </el-table-column>
      <el-table-column prop="age" label="Age"/>
      <el-table-column prop="last_appointment_date" label="Last appointment">
        <template #default="scope">
          <div v-if="scope.row.cf_patient_details" class="text-center">
            <p class="mt-0 mb-0">{{ scope.row.cf_patient_details.last_appointment_date }}</p>
            <div v-if="scope.row.cf_patient_details.last_appointment_status">
              <el-tag class="ml-2 " type="success"
                      v-if="scope.row.cf_patient_details.last_appointment_status == 'booked'">
                {{ scope.row.cf_patient_details.last_appointment_status }}
              </el-tag>
              <el-tag class="ml-2 " type="danger" v-else>{{
                  scope.row.cf_patient_details.last_appointment_status
                }}
              </el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="actions" class="text-center" label="Actions">
        <template #default="scope">
          <router-link class="m-5" :to="`/cancer/patient/details/${scope.row.id}`">
            <el-button type="success" circle>
              <el-icon>
                <View/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link class="m-5" :to="`/appointments?id=${scope.row.id}&name=${scope.row.fullname}`">
            <el-button type="info" circle>
              <el-icon>
                <Checked/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link :to="`/cancer-patient/update/${scope.row.id}`">
            <el-button type="primary" circle>
              <el-icon>
                <font-awesome-icon icon="fa fa-edit"/>
              </el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <br>

    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="getPatients"
    />

    <!--  modal  -->
    <div>
      <AddPatient :cancer_patient="true" @getPatients="getPatients" @modalClosed="is_change_modal_open=false"
                  v-if="is_change_modal_open"/>
    </div>
    <el-dialog v-model="searchDoctorDialog" class="appointment-status-modal">
      <div v-if="patients.length">
        <div v-for="(patient, index) in patients" :key="index" class="single_patient d-flex justify-content-between">
          <div>
            <h3 class="mt-0 mb-0">{{ patient.fullname }}</h3>
            <p class="mb-0 mt-0">{{ patient.phone_number }}</p>
          </div>
          <div>
            <router-link :to="`/appointments?id=${patient.id}&name=${patient.fullname}`">
              <el-button type="success">Get Appointment</el-button>

            </router-link>
            <!--            has_cancer_pre_assessment-->

            <!--            has_cancer_pre_screening-->

            <el-button type="primary" v-if="patient.has_cancer_pre_assessment == 0" class="mx-5"
                       @click="makeACancerPatient(patient)">Add Pre Assessment
            </el-button>
            <el-button type="primary" v-else-if="patient.has_cancer_pre_screening==0" class="mx-5"
                       @click="preScreeningPush(patient)">Add Pre Screening Data
            </el-button>
            <el-button type="primary" v-else class="mx-5" @click="view(patient)">View
            </el-button>

          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <el-empty :image-size="100" description="No patient found"/>
        <!--        <el-button type="success" @click="createNewPatient">Create New Patient</el-button>-->
        <PatientForm :phone_number="search_form.patient_q" @dialogClose="dialogClose"
                     @selectedPatient="selectedPatient"/>
      </div>

    </el-dialog>
    <el-dialog v-model="pre_assessment_dialog" title="Add pre assessment" class="appointment-status-modal">
      <div>
        <el-form v-model="pre_assessment_question" label-position="top">
          <el-card shadow="never">
            <el-form-item v-for="(pre_assessment, index) in pre_assessment_question" :key="index"
                          :label="`${index+1}. ${pre_assessment.title}`">
              <el-radio-group v-model="pre_assessment.value">
                <el-radio :label="1">Yes</el-radio>
                <el-radio :label="0">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-card>
          <br>
          <span class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitPreassesmentForm('form')">
          Confirm
        </el-button>
      </span>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import AddPatient from '@/components/modal/AddPatientModal';
import PatientForm from '@/components/form/PatientForm';
import { CommonFunction } from '@/mixins/CommonFunction';
import axios from 'axios';

export default {
  name: 'CancerScreeningPatients',

  components: {
    AddPatient,
    PatientForm,
  },
  mixins: [CommonFunction],
  data() {
    return {
      tableData: [],
      search: '',
      dialogFormVisible: false,
      is_change_modal_open: false,
      loading: false,
      total: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      searchDoctorDialog: false,
      q: '',
      search_form: {
        patient_q: '',
      },
      search_rules: {
        patient_q: [
          {
            required: true,
            min: 11,
            message: 'Please input number',
          },
        ],
      },
      patients: [],
      pre_assessment_dialog: false,
      pre_assessment: {},
      pre_assessment_question: [
        {
          id: '1',
          title: 'Change the bowel or bladder habits',
          value: 0,
        },
        {
          id: '2',
          title: ' A sore that does not heal',
          value: 0,
        },
        {
          id: '3',
          title: ' Unusual bleeding or discharge',
          value: 0,
        },
        {
          id: '4',
          title: 'Thickening or lump in breast or elsewhere',
          value: 0,
        },
        {
          id: '5',
          title: 'Indigestion or difficulty in swallowing',
          value: 0,
        },
        {
          id: '6',
          title: 'Obvious change in warts or male',
          value: 0,
        },
        {
          id: '7',
          title: ' Nagging cough or  hoarseness',
          value: 0,
        },
      ],
      questions: [],
      patient: {},
    };
  },
  created() {
    this.getPatients();
  },
  computed: {
    filteredList() {
      return this.tableData.filter(
        (data) => !this.search
              || data.fullname.toLowerCase()
                .includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    getPatients(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v1/cancer/patient/list?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.q ? `&q=${this.q}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total_count;
          this.loading = false;
        });
    },
    getSearchPatients() {
      this.loading = true;
      axios.get(`/api/v1/patient/subordinate/list?term=${this.search_form.patient_q}`)
        .then((response) => {
          this.patients = response.data.data;
          this.loading = false;
        });
    },
    openModal() {
      this.is_change_modal_open = true;
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.q = '';
      this.getPatients();
    },
    searchPatient(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          this.getSearchPatients();
          this.searchDoctorDialog = true;
        } else {
          return true;
        }
      });
    },
    dialogClose() {
      this.searchDoctorDialog = false;
    },
    selectedPatient(value) {
      this.$router.push(`/appointments?id=${value.id}&name=${value.fullname}`);
    },
    makeACancerPatient(patient) {
      this.pre_assessment_dialog = true;
      this.searchDoctorDialog = false;
      this.patient = patient;
    },
    preScreeningPush(patient) {
      this.$router.push({ path: `/cancer-patient/update/${patient.id}` });
    },
    view(patient) {
      this.$router.push({ path: `/cancer/patient/details/${patient.id}` });
    },
    closeModal() {
      this.pre_assessment_dialog = false;
    },
    submitPreassesmentForm() {
      const formData = new FormData();
      this.pre_assessment_question.map((item) => {
        formData.append(`questions[${item.id}]`, item.value);
      });
      formData.append('patient_id', this.patient.id);
      axios.post('/api/v1/cancer/pre/assessment/user/response', formData)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: 'Success',
              message: 'Successfully saved',
              type: 'success',
              duration: 2000,
            });
            return this.$router.push(`/cancer-patient/update/${this.patient.id}`);
          }
          this.$notify({
            title: 'Failed',
            message: res.data.message,
            type: 'danger',
            duration: 2000,
          });
        });
    },
  },
  title: 'Cancer Screening Patients',
};
</script>

<style scoped>

</style>
