<template>
    <div>
      <div v-if="!showMore">
        {{ truncatedText }}
        <button @click="showMore = true">More</button>
      </div>
      <div v-else>
        {{ fullText }}
        <button @click="showMore = false">Less</button>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        showMore: false,
        truncatedText: '',
      };
    },
    props: {
      text: {
        type: String,
        required: true,
      }
    },
    computed: {
      fullText() {
        return this.text;
      }
    },
    mounted() {
      this.truncateText();
    },
    methods: {
      truncateText() {
        // Adjust the desired character limit according to your needs
        const characterLimit = 100;
  
        if (this.text.length > characterLimit) {
          this.truncatedText = this.text.slice(0, characterLimit) + '...';
        } else {
          this.truncatedText = this.text;
        }
      }
    }
  };
  </script>
<style scoped>
button {
    background: none;
    color: blue;
    border: 0px;
}
</style>
  