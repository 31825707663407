<template>
  <div>
    <el-card>
      <div class="text-right">
        <router-link :to="`/zero-club-foot-patient/update/${$route.params.id}`">
          <el-button type="primary" circle>
            <el-icon>
              <font-awesome-icon icon="fa fa-edit"/>
            </el-icon>
          </el-button>
        </router-link>
      </div>

      <div class="text-center">
        <img :src="require('@/assets/logo.png')" alt="Orko" class="logo" id="sidebar-logo">
        <p>CONNECT.CARE</p>
      </div>
      <h2 class="text-center">রোগীর রেকর্ড ফরম</h2>

      <h3 class="mb-0"> ক্লাব ফুট </h3>
      <table width="180">
        <tr>
          <td> রোগীর নাম: <span class="mx-10">{{ patient.fullname }}</span></td>
          <td> রেজি নং: <span class="mx-10">{{ patient.registration_number }}</span></td>
          <td> লিঙ্গ: <span class="mx-10">{{ patient.gender }}</span></td>
          <td> জন্ম তারিখ: <span class="mx-10">{{ patient.dob }}</span></td>
        </tr>
        <tr>
          <td> বাবার নাম: <span class="mx-10">{{
              patient.father_name ? patient.father_name : '-'
            }}</span></td>
          <td>মায়ের নাম: <span class="mx-10">{{
              patient.mother_name ? patient.mother_name : '-'
            }}</span></td>
          <td>বাবার বয়স: <span class="mx-10">{{
              patient.cf_patient_details ? patient.cf_patient_details.father_age : '-'
            }}</span></td>
          <td>মায়ের বয়স: <span class="mx-10">{{
              patient.cf_patient_details ? patient.cf_patient_details.mother_age : '-'
            }}</span></td>
        </tr>
        <tr>
          <td>গ্রাম: <span class="mx-10">{{
              patient.cf_patient_details ? patient.cf_patient_details.village : '-'
            }}</span></td>
          <td>পোস্ট অফিস: <span class="mx-10">{{
              patient.cf_patient_details ? patient.cf_patient_details.po : '-'
            }}</span></td>
          <td>থানা/উপজেলা <span class="mx-10">{{ patient.location }}</span></td>
          <td>জেলা: <span class="mx-10">{{ patient.location }}</span></td>
        </tr>
        <tr>
          <td>মোবাইল নং:(১) <span class="mx-10">{{ patient.phone_number }}</span></td>
          <td>মোবাইল নং:(২) <span class="mx-10">{{
              (patient.cf_patient_details && patient.cf_patient_details.phone_number_2 ) ? patient.cf_patient_details.phone_number_2 : '-'
            }}</span></td>
          <td colspan="2">ই-মেইল: <span class="mx-10">{{ patient.email }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_details">
          <td colspan="2">পরিবারের কারো মুগুর পা আছে? <span class="mx-10"> {{
              patient.cf_patient_details ? patient.cf_patient_details.no_of_cf_patient > 0 ? ' - হ্যা' : ' - না' : ''
            }}</span></td>
          <td v-if="patient.cf_patient_details.no_of_cf_patient!=0" colspan="1">পরিবারের কয় জনের মুগুর পা আছে ? <span
              class="mx-10"> {{
              patient.cf_patient_details.no_of_cf_patient ? patient.cf_patient_details.no_of_cf_patient + ' জনের' : ''
            }}</span></td>
          <td colspan="1">রেফারেন্স : <span
              class="mx-10"> {{
              patient.cf_patient_details.ref_by ? patient.cf_patient_details.ref_by : ''
            }}</span></td>
        </tr>

        <!--        <tr v-if="patient.cf_patient_pregnancy_details">-->
        <!--          <td colspan="2">প্রসবকালীন ও প্রসব পরবর্তী বাচ্চার কোন সমস্যা হয়েছিল কি? <span-->
        <!--              class="mx-10">{{ patient.cf_patient_pregnancy_details.child_faced_problem }}</span></td>-->
        <!--          <td colspan="2">জন্মগ্রহনের পর আপনার সন্তান কখন কেঁদেছিলো?  <span-->
        <!--              class="mx-10">{{ patient.cf_patient_pregnancy_details.child_cried_after == 1 ? 'স্বাভাবিক' : patient.cf_patient_pregnancy_details.child_cried_after+'সময় পর' }}</span></td>-->
        <!--        </tr>-->
        <!--        <tr v-if="patient.cf_patient_pregnancy_details">-->
        <!--          <td colspan="2">শিশুটি কি আইসিউতে রাখা হয়েছিল? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.child_in_icu == 1 ? patient.cf_patient_pregnancy_details.child_in_icu : 'না' }}</span></td>-->
        <!--          <td colspan="2">রোগীর সমস্যা কত বছর বয়সে প্রথম ধরতে পেরেছিলেন? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.first_cf_identified }}</span></td>-->
        <!--        </tr>-->
        <!--        <tr v-if="patient.cf_patient_pregnancy_details">-->
        <!--          <td colspan="2">আপনার সন্তান কখন প্রথম হাঁটা শুরু করে? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.first_cf_identified }}</span></td>-->
        <!--          <td colspan="2">রোগীর সমস্যা কত বছর বয়সে প্রথম ধরতে পেরেছিলেন? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.first_walked == 1 ? patient.cf_patient_pregnancy_details.first_walked : 'স্বাভাবিক' }}</span></td>-->
        <!--        </tr>-->
        <!--        <tr v-if="patient.cf_patient_pregnancy_details">-->
        <!--          <td colspan="2">বাচ্চার অন্য আর কোন সমস্যা আছে কি? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.child_has_any_problem != 1 ? patient.cf_patient_pregnancy_details.child_has_any_problem : 'না' }}</span></td>-->
        <!--          <td colspan="1">প্রসবের সময় বাচ্চার ওজন: <span class="mx-10">{{ patient.cf_patient_pregnancy_details.weight }}</span></td>-->
        <!--          <td colspan="1">পূর্বে কোন চিকিৎসা হয়েছিল? <span class="mx-10">{{ patient.cf_patient_pregnancy_details.treatment != 1 ? patient.cf_patient_pregnancy_details.treatment : 'না' }}</span></td>-->
        <!--        </tr>-->

      </table>
      <br>
      <h3 class="mb-0">প্রসব পূর্ববর্তী</h3>

      <table>
        <tr v-if="patient.cf_patient_pregnancy_details">

          <td>গর্ভ ধারণের সর্বমোট সময়:
            <span class="mx-10">
              {{
                checkValue(patient.cf_patient_pregnancy_details.pregnancy_on_time) ? ' ' : patient.cf_patient_pregnancy_details.pregnancy_on_time == 1 ? 'সঠিক সময়' : 'সময়ের আগে'
              }}
            </span>
          </td>
          <td>গর্ভ ধারণের সময়: <span class="mx-10">{{
              patient.cf_patient_pregnancy_details.pregnancy_time ? patient.cf_patient_pregnancy_details.pregnancy_time + ' মাস' : ''
            }} </span>
          </td>
          <td colspan="2">প্রসবের সময় মায়ের বয়স: <span
              class="mx-10">{{
              patient.cf_patient_pregnancy_details.mother_age_during_pregnancy ? patient.cf_patient_pregnancy_details.mother_age_during_pregnancy + ' বছর' : ''
            }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td>কোন বড় ধরণের অসুখ হয়েছিল: <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.any_major_disease) ? ' ' : patient.cf_patient_pregnancy_details.any_major_disease == 1 ? 'হ্যাঁ' : 'না'
            }}</span>
          </td>
          <td colspan="3">গর্ভা অবস্থায় কোন সমস্যা সম্মুখীন হয়েছিল: <span
              class="mx-10">{{ patient.cf_patient_pregnancy_details.prob_during_pregnancy }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td>গর্ভাবস্থায় ডায়াবেটিকস ছিল: <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.has_diabetes) ? ' ' : patient.cf_patient_pregnancy_details.has_diabetes == 1 ? 'হ্যাঁ' : 'না'
            }}</span></td>
          <td>গর্ভাবস্থায় উচ্চরক্তচাপ ছিল: <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.has_high_bp) ? ' ' : patient.cf_patient_pregnancy_details.has_high_bp == 1 ? 'হ্যাঁ' : 'না'
            }}</span></td>
          <td colspan="2">গর্ভাবস্থায় কোন টিকা দিয়েছিলেন? <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.tika) ? ' ' : patient.cf_patient_pregnancy_details.tika == 1 ? 'হ্যাঁ' : 'না'
            }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td colspan="2"> আল্ট্রাসনোগ্রাফি করেছিলেন: <span
              class="mx-10">{{
              patient.cf_patient_pregnancy_details.no_of_ultra > 0 ? patient.cf_patient_pregnancy_details.no_of_ultra + ' বার' : ' না'
            }}</span></td>
          <td colspan="2">আল্ট্রাসোনোগ্রাফিতে কোন সমস্যা ধরা পড়েছিল: <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.prob_found_ultra) ? ' ' : patient.cf_patient_pregnancy_details.prob_found_ultra == 1 ? 'হ্যাঁ' : 'না'
            }}</span>
          </td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td colspan="2">গর্ভকালীন সময় ওষুধ গ্রহণ: <span
              class="mx-10">{{ patient.cf_patient_pregnancy_details.medicine_during_pregnancy }}</span></td>

          <td colspan="1">প্রসবের স্থান:
            <span class="mx-10" v-if=" patient.cf_patient_pregnancy_details.birth_place == 'home'"> নিজ বাড়ি </span>
            <span class="mx-10" v-else-if="patient.cf_patient_pregnancy_details.birth_place == 'govt_clinic'"> স্থানীয় সরকার ক্লিনিক </span>
            <span class="mx-10" v-else-if="patient.cf_patient_pregnancy_details.birth_place == 'ngo_clinic'"> এনজিও পরিচালিত ক্লিনিক </span>
            <span class="mx-10" v-else-if="patient.cf_patient_pregnancy_details.birth_place == 'pvt_clinic'"> প্রাইভেট ক্লিনিক </span>
            <span class="mx-10"
                  v-else-if="patient.cf_patient_pregnancy_details.birth_place == 'other'"> অন্যান্য </span>
          </td>
          <td colspan="1">প্রসবের প্রক্রিয়া: <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.normal_birth) ? ' ' : patient.cf_patient_pregnancy_details.normal_birth == 1 ? 'নরমাল' : 'সিজার'
            }}</span></td>
        </tr>
      </table>
      <br>
      <h3 class="mb-0">প্রসব পরবর্তী</h3>
      <table>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td colspan="2">প্রসবকালীন ও প্রসব পরবর্তী বাচ্চার কোন সমস্যা হয়েছিল কি? <span
              class="mx-10">{{
              patient.cf_patient_pregnancy_details.child_faced_problem == 'no' ? 'না' : patient.cf_patient_pregnancy_details.child_faced_problem
            }}</span></td>
          <td colspan="2">জন্মগ্রহনের পর আপনার সন্তান কখন কেঁদেছিলো? <span
              class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.child_cried_after) ? ' ' : patient.cf_patient_pregnancy_details.child_cried_after == 1 ? 'স্বাভাবিক' : patient.cf_patient_pregnancy_details.child_cried_after + ' মিনিট সময় পর'
            }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">
          <td colspan="2">শিশুটি কি আইসিউতে রাখা হয়েছিল? <span class="mx-10">{{
              checkValue(patient.cf_patient_pregnancy_details.child_in_icu) ? ' ' : patient.cf_patient_pregnancy_details.child_in_icu > 0 ? patient.cf_patient_pregnancy_details.child_in_icu + ' দিন ' : 'না'
            }}</span></td>
          <td colspan="2">রোগীর সমস্যা কত বছর বয়সে প্রথম ধরতে পেরেছিলেন? <span
              class="mx-10">{{
              patient.cf_patient_pregnancy_details.first_cf_identified ? patient.cf_patient_pregnancy_details.first_cf_identified + ' বছর' : ''
            }}</span></td>
        </tr>

        <tr v-if="patient.cf_patient_pregnancy_details">

          <td colspan="2">আপনার সন্তান কখন প্রথম হাঁটা শুরু করে? <span class="mx-10">{{
              patient.cf_patient_pregnancy_details.first_walked > 0 ? patient.cf_patient_pregnancy_details.first_walked + ' বছর' : 'স্বাভাবিক'
            }}</span></td>
          <td colspan="2">বাচ্চার অন্য আর কোন সমস্যা আছে কি? <span class="mx-10">{{
              patient.cf_patient_pregnancy_details.child_has_any_problem != 1 ? patient.cf_patient_pregnancy_details.child_has_any_problem : 'না'
            }}</span></td>
        </tr>
        <tr v-if="patient.cf_patient_pregnancy_details">

          <td colspan="2">প্রসবের সময় বাচ্চার ওজন: <span class="mx-10">{{
              patient.cf_patient_pregnancy_details.weight ? patient.cf_patient_pregnancy_details.weight + ' কেজি ' : ''
            }}</span></td>
          <td colspan="1">পূর্বে কোন চিকিৎসা হয়েছিল? <span class="mx-10">
             {{
              patient.cf_patient_details.treatment == 0 ? ' না' : patient.cf_patient_details.treatment
            }}</span></td>
        </tr>
      </table>

    </el-card>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'ZeroClubFootDetails',
  data() {
    return {
      patient: {},
      loading: false,
      pregnancy_before: {},
      pregnancy_after: {},
      prob_during_pregnancy: [],
      medicine_during_pregnancy: [],
      no_of_ultra: '',
      child_faced_problem: '',
      no_of_cf_patient: '',
      child_cried_after: '',
      child_in_icu: '',
      first_walked: '',
      treatment: '',
      child_has_any_problem: '',
    };
  },
  created() {
    this.getPatientDetails();
  },
  methods: {
    getPatientDetails() {
      this.loading = true;
      axios.get(`/api/v1/user/profile/details/${this.$route.params.id}`)
        .then((response) => {
          this.patient = response.data.data;

          if (response.data.data.cf_patient_pregnancy_details && response.data.data.no_of_ultra > 0) {
            this.no_of_ultra = 1;
          } else {
            this.no_of_ultra = 0;
          }

          if (response.data.data.cf_patient_pregnancy_details && response.data.data.cf_patient_pregnancy_details.medicine_during_pregnancy) {
            this.medicine_during_pregnancy = response.data.data.cf_patient_pregnancy_details.medicine_during_pregnancy.split(',');
          }
          if (response.data.data.cf_patient_pregnancy_details && response.data.data.cf_patient_pregnancy_details.prob_during_pregnancy) {
            this.prob_during_pregnancy = response.data.data.cf_patient_pregnancy_details.prob_during_pregnancy.split(',');
          }
          if (response.data.data.cf_patient_pregnancy_details) {
            // pregnancy before
            this.pregnancy_before = response.data.data.cf_patient_pregnancy_details;
            // pregnancy after
            if (response.data.data.cf_patient_pregnancy_details.child_in_icu > 0) {
              this.child_in_icu = 1;
            } else {
              this.child_in_icu = 0;
            }
            if (response.data.data.cf_patient_pregnancy_details.first_walked > 0) {
              this.first_walked = 1;
            } else {
              this.first_walked = 0;
            }
            if (response.data.data.cf_patient_pregnancy_details.child_has_any_problem) {
              this.child_has_any_problem = 1;
            } else {
              this.child_has_any_problem = 0;
            }
            this.pregnancy_after = {
              child_faced_problem: response.data.data.cf_patient_pregnancy_details.child_faced_problem,
              child_cried_after: response.data.data.cf_patient_pregnancy_details.child_cried_after,
              child_in_icu: response.data.data.cf_patient_pregnancy_details.child_in_icu,
              first_cf_identified: response.data.data.cf_patient_pregnancy_details.first_cf_identified,
              first_walked: response.data.data.cf_patient_pregnancy_details.first_walked,
              child_has_any_problem: response.data.data.cf_patient_pregnancy_details.child_has_any_problem,
              treatment: response.data.data.cf_patient_pregnancy_details.treatment,
              weight: response.data.data.cf_patient_pregnancy_details.weight,
            };

            if (response.data.data.cf_patient_pregnancy_details.child_faced_problem == 'no') {
              this.child_faced_problem = 'no';
              this.pregnancy_after.child_faced_problem = '';
            }
            if (response.data.data.cf_patient_pregnancy_details.child_cried_after == 1) {
              this.child_cried_after = response.data.data.cf_patient_pregnancy_details.child_cried_after.toString();
            }
          }
          this.loading = false;
        });
    },
    checkValue(strValue) {
      if (strValue !== 0) {
        if (strValue == null || strValue == '') {
          return true;
        }
        return false;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px 0px;
  padding: 0;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;

}

h3 {
  background: #000000;
  color: #ffffff;
  padding: 10px 2px;
}

.logo {
  height: 80px;
}
</style>
