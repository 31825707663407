<template>
  <div>
    <el-dialog
        v-model="examinationDialog"
        title="Piran Score"
        class="appointment-status-modal">
      <el-form label-position="top">
        <el-form-item label="Examination Date" prop="evaluation_date" class="w-100">
          <div class="block w-100">
            <el-date-picker
                class="w-100"
                v-model="evaluation_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
          </div>
        </el-form-item>
        <el-row :gutter="20" v-for="(examination, index) in examinations_pirani" :key="index">
          <el-col :span="10">
            <el-select class="m-2" placeholder="Select One"
                       v-model="examination.type_id" clearable @change="checkPirani(examination)">
              <el-option
                  v-for="item in examinations_signs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <label for="" v-if="index == 0">Foot</label>
            <div>
              <el-radio-group v-model="examination.sides">
                <el-radio label="L">L</el-radio>
                <el-radio label="R">R</el-radio>
              </el-radio-group>
            </div>
            <div v-if="examination.sides">
              <el-radio-group v-model="examination.term">
                <el-radio :label="0">0</el-radio>
                <el-radio :label="0.5">0.5</el-radio>
                <el-radio :label="1">1</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-col :span="4" class="mt-3">
            <el-button @click="removeExamination(examinations_pirani,index)" type="danger" icon="Delete" circle/>
          </el-col>
        </el-row>

        <el-button class="text-right" type="primary" @click="addMoreExaminationPirani">Add</el-button>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="examinationDialog = false">Cancel</el-button>
          <el-button type="primary" @click="submitExamination('form')">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddPiraniScoreModal',
  props: {
    appointment_details: {},
    pirani_patient_profile: {},
  },
  data() {
    return {
      examinationDialog: false,
      evaluation_date: this.todayDate(),
      examinations_signs: [],
      examinations_pirani: [],
    };
  },
  created() {
    this.examinationDialog = true;
    if (this.examinationDialog) {
      this.getMedicalExamination();
    }
  },
  methods: {
    getMedicalExamination() {
      axios.get('/api/v1/medical-general-examination/list?type=pirani')
        .then((res) => {
          this.examinations_signs = res.data.data;
        });
    },
    addMoreExaminationPirani() {
      this.examinations_pirani.push({
        type_id: '',
        term: '',
        sides: '',
      });
    },
    checkPirani(exam) {
      const examObj = this.examinations_pirani.find((item) => item.type_id == exam.type_id);
      if (examObj && examObj.sides == 'L') {
        exam.sides = 'R';
      } else if (examObj && examObj.sides == 'R') {
        exam.sides = 'L';
      }
    },
    removeExamination(examination, index) {
      examination.splice(index, 1);
    },
    vitalSignValueCheck(item) {
      if (item.type_id && item.term) {
        return true;
      }
      return false;
    },
    submitExamination() {
      this.examinations_pirani = this.examinations_pirani.map((item) => {
        item.evaluation_date = this.evaluation_date;
        return item;
      });
      let url = '';
      if (this.pirani_patient_profile) {
        url = `/api/v2/appointment/${this.$route.params.id}/medical-examinations-by-patient`;
      } else {
        url = `/api/v2/appointment/${this.appointment_details.id}/medical-examinations?type=pirani`;
      }
      axios.post(url, { examinations: this.examinations_pirani })
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: 'Success',
              message: 'Successfully saved',
              type: 'success',
              duration: 2000,
            });
            this.examinationDialog = false;
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
  },
  watch: {
    examinationDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
        this.examinations_pirani = [];
      }
    },
  },
};
</script>

<style scoped>

</style>
