<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <el-col :sm="24" :md="12" :lg="12" v-if="authUser.user_type == 'executive' || authUser.user_type == 'crm-manager'">
        <el-select @change="getPatients()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large" clearable>
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row justify="space-between">
      <el-col :sm="24" :md="12" :lg="12">
        <el-button type="primary" @click="openModal" v-if="checkUsrPermissions('patient add')">
          Create new patient
        </el-button>
      </el-col>

      <el-col :sm="24" :md="12" :lg="12">
        <el-form
            :model="search_form"
            :rules="search_rules"
            label-position="top"
            @submit.prevent="searchPatient('search_form')"
            ref="search_form"
        >
          <div class="d-flex align-items-center">
            <el-form-item label="Search Patient" prop="patient_q" class="w-100">
              <el-input
                  v-model="search_form.patient_q"
                  placeholder="Search Patient Phone Number"
                  class="input-with-select"
                  type="number"
                  min="0"
                  clearable
              >
              </el-input>
            </el-form-item>
            <el-button type="success" class="mt-5 mx-5" @click="searchPatient('search_form')" round>Search</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="20" align="middle" class="align-items-center">

      <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
        <p class="demonstration">Phone Number</p>
        <el-input v-model="q" size="large" type="number"/>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-if="$route.query.my_paitent!=1">
        <div class="mt-40">
          <el-checkbox v-model="show_my_patient" label="Show my patient" size="large"/>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getPatients" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
    </el-row>
    <br>

    <el-table :data="filteredList" border style="width: 100%">
      <el-table-column prop="image" label="Image">
        <template #default="scope">
          <div class="demo-type">
            <el-avatar v-if="scope.row.image" :size="60" :src="userPhoto(scope.row.image)">
              <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>
            <el-avatar v-else :size="60" src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png">
              <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fullname" label="Name"/>
      <el-table-column header-align="center" prop="phone_number" label="Phone"
                       v-if="authUser.user_type=='agent-pharmacy' || authUser.user_type=='cashier' || authUser.user_type=='executive'">
        <template #default="scope">
          {{ scope.row.phone_number }}
        </template>
      </el-table-column>
      <el-table-column prop="age" label="Age" width="80" align="center"/>
      <el-table-column prop="platform" label="Reg. Source" width="135" align="center">
        <template #default="scope">
          {{ (scope.row.platform == 'Poster')?'Sticker':scope.row.platform }}
        </template>
      </el-table-column>
      <el-table-column label="Is Called?" align="center">
        <template #default="scope">
          <el-tag
                v-if="scope.row.is_called"
                class="mx-1"
                type="success"
                effect="dark"
            >
              Yes
          </el-tag>
          <el-tag
                v-else
                class="mx-1"
                type="warning"
                effect="dark"
            >
              No
          </el-tag>
           
        </template> 
      </el-table-column>
      <el-table-column label="Is Relapsed?" align="center">
        <template #default="scope">
          <el-tag
                v-if="scope.row.cf_patient_details && scope.row.cf_patient_details.is_relapsed"
                class="mx-1"
                type="success"
                effect="dark"
            >
              Yes
          </el-tag>
          <el-tag
                v-else
                class="mx-1"
                type="warning"
                effect="dark"
            >
              No
          </el-tag>
           
        </template> 
      </el-table-column>
      <el-table-column prop="called_at" label="Called At" align="center"/>
      <el-table-column prop="actions" header-align="center" label="Actions" width="200">
        <template #default="scope">
          <router-link class="m-5" :to="`/patients/${scope.row.id}`" v-if="checkUsrPermissions('User profile')">
            <el-button type="success" circle>
              <el-icon>
                <View/>
              </el-icon>
            </el-button>
          </router-link>
          <router-link class="m-5" :to="`/appointments?id=${scope.row.id}&name=${scope.row.fullname}`">
            <el-button type="info" circle>
              <el-icon>
                <Checked/>
              </el-icon>
            </el-button>
          </router-link>
          <el-button type="danger"  circle @click="craeteCall(scope.row)">
              <el-icon><PhoneFilled /></el-icon>
          </el-button>
          <el-button type="warning" class="m-5" v-if="is_message" circle @click="sendMessage(scope.row)">
            <el-icon><ChatDotRound /></el-icon>
          </el-button>

        </template>
      </el-table-column>
    </el-table>
    <br>

    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="getPatients"
    />
    <el-dialog v-model="searchDoctorDialog" class="appointment-status-modal">
      <div v-if="patients.length">
        <div v-for="(patient, index) in patients" :key="index" class="single_patient d-flex justify-content-between">
          <div>
            <h3 class="mt-0 mb-0">{{ patient.fullname }}</h3>
            <p class="mb-0 mt-0">{{ patient.phone_number }}</p>
          </div>
          <div>
            <router-link :to="`/appointments?id=${patient.id}&name=${patient.fullname}`">
              <el-button type="success">Get Appointment</el-button>

            </router-link>
            <router-link :to="`/zero-club-foot-patient/update/${patient.id}`" v-if="!patient.cf_patient_details"
                         class="mx-5">
              <el-button type="primary">Make ZCF</el-button>
            </router-link>
            <router-link :to="`/zero-club-foot-patient/update/${patient.id}?reg_type=relapse`" v-if="patient.cf_patient_details"
                         class="mx-5">
              <el-button type="primary">Relapse Patient</el-button>
            </router-link>
            <router-link :to="`/patients/${patient.id}?zeroclubfoot=zeroclubfoot`" class="mx-5">
              <el-button type="info">Details</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <el-empty :image-size="100" description="No patient found"/>
        <!--        <el-button type="success" @click="createNewPatient">Create New Patient</el-button>-->
        <PatientForm :is_zero_club_foot="true" :phone_number="search_form.patient_q" @dialogClose="dialogClose"
                     @selectedPatient="selectedPatient"/>
      </div>

    </el-dialog>
    <!--  modal  -->
    <div>
      <AddPatient @getPatients="getPatients" @modalClosed="is_change_modal_open=false" v-if="is_change_modal_open"/>
    </div>
    <CallLogModal v-if="call_log_dailog" :patient_id="patient_log.id"  @modalClosed="call_log_dailog=false"/>
    <MessageModal v-if="message_dailog" :patient_id="patient_log.id"  @modalClosed="message_dailog=false"/>
  </div>
</template>

<script>
import axios from 'axios';
import { CommonFunction } from '@/mixins/CommonFunction';
import { mapGetters } from 'vuex';
import { CenterMixins } from '@/mixins/CenterMixins';
import AddPatient from '../../components/modal/AddPatientModal';
import CallLogModal from '../../components/modal/CallLogModal.vue';
import MessageModal from '../../components/modal/MessageModal';
export default {
  name: 'Patients',
  components: {
    AddPatient,
    CallLogModal,
    MessageModal,
  },
  props: {
    is_message: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [CommonFunction, CenterMixins],
  data() {
    return {
      tableData: [],
      search: '',
      dialogFormVisible: false,
      is_change_modal_open: false,
      loading: false,
      total: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      q: '',
      show_my_patient: '',
      search_form: {
        patient_q: '',
      },
      search_rules: {
        patient_q: [
          {
            required: true,
            min: 11,
            message: 'Please input number',
          },
        ],
      },
      searchDoctorDialog: false,
      patients: [],
      patient_log: {},
      call_log_dailog: false,
      message_dailog: false,
    };
  },
  created() {
    
    if(this.$route.query.my_paitent) {
      this.show_my_patient =  this.$route.query.my_paitent
    }
    this.getPatients();
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    filteredList() {
      return this.tableData.filter(
        (data) => !this.search
              || data.fullname.toLowerCase()
                .includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    getPatients(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      this.loading = true;
      const url = `/api/v2/partner/agent/patients?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}${this.q ? `&q=${this.q}` : ''}${this.show_my_patient == 1 ? '&show_my_patient=1' : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}`;
      axios.get(url)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total_count;
          this.loading = false;
        });
    },
    openModal() {
      this.is_change_modal_open = true;
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.q = '';
      this.getPatients();
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    searchPatient(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          this.getSearchPatients();
          this.searchDoctorDialog = true;
        } else {
          return true;
        }
      });
    },
    getSearchPatients() {
      this.loading = true;
      axios.get(`/api/v1/patient/subordinate/list?term=${this.search_form.patient_q}`)
        .then((response) => {
          this.patients = response.data.data;
          this.loading = false;
        });
    },
    craeteCall(row) {
      this.patient_log = row;
      this.call_log_dailog = true;
    },
    sendMessage(row) {
      this.patient_log = row;
      this.message_dailog = true;
    },
  },
  title: 'Patients',
};
</script>

<style scoped>

</style>
